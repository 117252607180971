import { IFramePage } from '@/app/pages/IFrame/IFrame.page'
import WorkflowRules from '@/app/pages/WorkflowRules/WorkflowRules.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/workflow-rules')({
  component: () => <IFramePage url='workflow-rules.html' />,
})

const WorkflowRulesLayout = () => {
  return (
    <DashboardLayout title="WorkflowRules Editor">
      <WorkflowRules />
    </DashboardLayout>
  )
}
