import * as z from 'zod';

const createEnv = () => {
  const EnvSchema = z.object({
    API_URL: z.string(),
    BASE_API_URL: z.string(),
    API_PREFIX: z.string().optional(),
    TARGET_APP_URL: z.string(),
    ROUTING_PREFIX: z.string(),
    TARGET_APP_LOGIN_URL: z.string(),
    APP_URL: z.string().optional().default('http://localhost:8001'),
  });


  type EnvSchemaKeys = keyof z.infer<typeof EnvSchema>;

  type EnvKeysType = {
    [K in EnvSchemaKeys as `${K}_KEY`]: K;
  };

  // Generate _KEY values for each entry in the schema
  const envKeys = Object.keys(EnvSchema.shape).reduce((acc, key) => {
    (acc as any)[`${key}_KEY`] = key;
    return acc;
  }, {} as EnvKeysType);

  type EnvSchemaType = z.infer<typeof EnvSchema>;

  // Parse environment variables
  const envVars = Object.entries(EnvSchema.shape).reduce<Record<string, string>>((acc, [key, value]) => {
    const envKey = key as keyof EnvSchemaType;
    const envValue = process.env[`REACT_APP_${envKey}`] || '';

    if (value instanceof z.ZodString) {
      acc[envKey] = envValue;
    } else if (value instanceof z.ZodEnum) {
      const enumValue = value.safeParse(envValue);
      if (enumValue.success) {
        acc[envKey] = enumValue.data ?? '';
      } else {
        console.warn(`Invalid value for ${envKey}: ${envValue}`);
      }
    }
    return acc;
  }, {});

  console.log(process.env);
  console.log(import.meta.env);
  console.log(envVars);
  const parsedEnv = EnvSchema.safeParse(envVars);

  if (!parsedEnv.success) {
    throw new Error(
      `Invalid env provided.
The following variables are missing or invalid:
${Object.entries(parsedEnv.error.flatten().fieldErrors)
  .map(([k, v]) => `- ${k}: ${v}`)
  .join('\n')}
`,
    );
  }
  return parsedEnv.data;
};

export const env = createEnv();
