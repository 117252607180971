import React, { useEffect } from 'react'
// import TextFieldElement from "../Atoms/TextFieldElement/TextFieldElement.component"
// import TextFieldElement from '../Atoms/TextField/MuiTextField.component'
import { Grid2 as Grid, Card, Paper, CardContent, Typography, Box, Autocomplete, AutocompleteRenderInputParams, IconButton, Tooltip } from '@mui/material'
// import Grid from '@mui/material/Grid2';
import { FormProvider, useFormContext } from 'react-hook-form'
import { useForm, Controller } from "react-hook-form"
import { useState } from 'react'
// import PhoneField from '../Atoms/TextField/PhoneField.component'
// import SingleDropdown from "../Molecules/Dropdowns/Singledropdown.component"
// import SingleDropdown from "../Molecules/Dropdowns/SingleDropdown.component"
// import DateField from '../Molecules/Date/DateField.component'
// import EmailField from '../Atoms/TextField/EmailField.component'
// import SelectElement from '../Atoms/Select/SelectElement.component'
// import { MuiDatePicker } from '@/components/Molecules/Date/MuiDatePicker.component'/
// import AutocompleteElement from '../Molecules/AutoComplete/AutoCompleteElement.component'
import dayjs from 'dayjs';
import TextFieldElement from '@/components/Atoms/TextField/MuiTextField.component'
import { AutoComplete, Button, MenuItem, TextField } from '@utilisourcepackagelibdev/utilisourcepackagelib'
import { Select } from '@mui/material'
// import SelectElement from '@/components/Atoms/Select/SelectElement.component'
// import { MuiDatePicker } from '@/components/Molecules/Date/DateField.component'
// import MuiDatePicker from '@/components/Molecules/Date/DateField.component'
import useTicketList from '@/contexts/stores/ticketList.store'
import useTicket from '@/hooks/fetches/useTicket.service'
// import { set } from 'date-fns'
import { DatePicker } from '@mui/x-date-pickers'
import TextBox from '@/components/Molecules/Textbox/Textbox.component'
import { enqueueSnackbar } from 'notistack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import { MuiDatePicker } from '@/components/Molecules/Date/MuiDatePicker.component'
// import { DatePicker } from '@mui/x-date-pickers'
// sx={{minHeight: "15vh", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;", borderRadius: "8px"}}

// const newOptions =
//   [
//     { id: "option1", name: "Drop", value: "Drop" },
//     { id: "option2", name: "CLOSED", value: "CLOSED" },
//     { id: "option3", name: "normal", value: "normal" },
//     { id: "option4", name: "Dylan Patton", value: "Dylan Patton" },
//   ]

export default function BaseInformation() {

  const { ticketListState, updateTicketList } = useTicketList();
  const methods = useFormContext();
  const { formState, control } = methods;

  // console.log('ticketListState:', ticketListState);

  const {
    ticketById,
    // ticketViewers,
    // allTickets,
    statusesByCustomer,
    ticketTypesByCustomer,
    priorities,
    agentsByCustomer
  } = useTicket(
    ticketListState?.selectedTicket?.id,
    ticketListState?.selectedCustomer?.id,
    ticketListState?.selectedCategory?.label
  );
  // const { data: statusesByCustomerData } = statusesByCustomer || {};

  // console.log('statusesByCustomerData:', statusesByCustomerData)


  // const [open, setOpen] = useState(false);
  // const [priorityObj, setPriorityObj] = useState({});
  // const [initialized, setInitialized] = useState(false);
  // const methods = useForm();

  // const handleSubmit = (formValues: any) => {
  //   console.log("Form Values: ", formValues);
  // };

  // console.log('ticketById?.data:', ticketById?.data)
  // console.log('types:', types)
  // console.log('priorities:', priorities)
  // console.log('agents:', agents)
  // console.log('methods:', methods)
  // console.log('props:', props)
  // console.log('props?.Assignee:', props?.Assignee)
  // console.log('statusesByCustomer?.data:', statusesByCustomer?.data);

  // const { setValue } = useFormContext();

  // const onDataChange = (id: string, value: string) => {
  //   console.log(props.CustomerEntity.Description)
  //   console.log(id, ":= " + value);
  // };

  // console.log(props?.Assignee)
  // console.log(dayjs(props?.Ticket?.DueDate))
  // console.log(props)

  const createPriorityObj = (priorityString: string) => {
    // console.log('2 props.Ticket?.Priority:', props?.Ticket?.Priority)
    if (priorityString) {
      switch (priorityString) {
        case "low":
          return {
            id: 0,
            label: "Low"
          }
          // setPriorityObj({
          //   id: 0,
          //   label: "Low"
          // });
          break;
        case "normal":
          return {
            id: 1,
            label: "Normal"
          }
          // setPriorityObj({
          //   id: 1,
          //   label: "Normal"
          // });
          break;
        case "high":
          return {
            id: 2,
            label: "High"
          }
          // setPriorityObj({
          //   id: 2,
          //   label: "High"
          // });
          break;
        case "critical":
          return {
            id: 3,
            label: "Critical"
          }
          // setPriorityObj({
          //   PriorityID: 3,
          //   Name: "Critical"
          // });
          break;
        // default:
        // setPriorityObj({});
      }
    }
  };

  const getColor = (colorValue: number) => {
    const color = `#${(colorValue >>> 8).toString(16).padStart(6, '0')}`;
    return color;
  };

  const getBrightness = (color: any) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const getTextColor = (backgroundColor: any) => {
    return getBrightness(backgroundColor) > 186 ? 'black' : 'white';
  };

  const handleAssignToMeClick = () => {
    const signedInUser = 516; //todo: once B2C is ready, get signed in user
    const signedInUserObj = agentsByCustomer?.data?.VisibleAgents.find((agent: { AgentId: number }) => agent.AgentId === signedInUser);
    if (signedInUserObj) {
      methods.setValue('assignee', { id: signedInUserObj.AgentId, label: signedInUserObj.Name });
    } else {
      enqueueSnackbar('Cannot assign ticket to you', { variant: 'error' });
    }
  }

  const handleLatLonCopy = () => {
    navigator.clipboard.writeText(ticketById?.data?.Latitude + " " + ticketById?.data?.Longitude).then(() => {
      enqueueSnackbar('Coordinates copied to clipboard', { variant: 'success' });
    })
  }

  // useEffect(() => {
  //   // if (props && !initialized) {
  //     console.log('3 props.Ticket?.Priority:', props?.Ticket?.Priority)
  //     console.log('PriorityID:', priorityObj)
  //     const potato = createPriorityObj(props?.Ticket?.Priority)
  //     console.log('potato:', potato)
  //     setValue('description', props?.Ticket?.Title || '');
  //     setValue('type', props?.Type || null);
  //     setValue('priority', createPriorityObj(props?.Ticket?.Priority) || null);
  //     setValue('status', props?.Status || null);
  //     setValue('dueDate', dayjs(props?.Ticket?.DueDate) || null);
  //     setValue('assignee', props?.Assignee || null);
  //     setValue('clientName', props?.Ticket?.ClientName || '');
  //     setValue('clientEmail', props?.Ticket?.ClientEmail || '');
  //     setValue('clientPhone', props?.Ticket?.ClientPhone || '');
  //     setValue('clientAddress', props?.Ticket?.ClientAddress || '');

  //     // setInitialized(true);  // Prevent re-initialization
  //   // }
  // }, [props, setValue, initialized]);


  useEffect(() => {
    if (ticketById.data && ticketListState?.selectedTicket?.id) {
      const { data } = ticketById
      // console.log('dayjs(data?.Ticket?.DueDate):', dayjs(data?.Ticket?.DueDate))
      // console.log('BaseInformation methods.reset')
      methods.reset({
        ...methods.getValues(),
        description: data?.Ticket?.Title || '',
        type: data?.Type
          ? {
            id: data.Type.TypeId,
            label: data.Type.Name,
          }
          : null,
        priority: createPriorityObj(data?.Ticket?.Priority) || null,
        status: data?.Status
          ? {
            StatusId: data.Status.StatusId,
            Name: data.Status.Name,
            Color: data.Status.Color
          }
          : null,
        dueDate: (data?.Ticket?.DueDate !== "0001-01-01T00:00:00Z") ? dayjs(data?.Ticket?.DueDate) : null,
        assignee: data?.Assignee
          ? {
            id: data.Assignee.AgentId,
            label: data.Assignee.Name,
          }
          : null,
        clientName: data?.Ticket?.ClientName || '',
        clientEmail: data?.Ticket?.ClientEmail || '',
        clientPhone: data?.Ticket?.ClientPhone || '',
        clientAddress: data?.Ticket?.ClientAddress || '',
      });
    }
  }, [ticketById.data, ticketListState?.selectedTicket?.id]);

  // {console.log('formState.errors:', formState.errors)}


  return (
    // <form onSubmit={handleSubmit}>
    <Grid size={{ xs: 12 }}>
      <Grid container spacing={3} display={"flex"} flexWrap={"wrap"}>
        <Grid size={12}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            {/* <Controller
                  name="description"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="description"
                      placeholder="Description"
                      label="Description"
                      // value={field.value || ''}
                      required
                      fullWidth
                      size="small"
                      error={!!methods.formState.errors.description}
                      helperText={methods.formState.errors.description?.message}
                    />
                  )}
                /> */}
            <TextBox
              name="description"
              control={control}
              label="Description"
              required
              size="small"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            {/* <Controller
                  name="type"
                  control={methods.control}
                  render={({ field }) => (
                    <Autocomplete
                    {...field}
                    options={ticketTypesByCustomer && ticketTypesByCustomer?.data || []}
                    getOptionLabel={(option: any) => option.Name}
                    isOptionEqualToValue={(option, value) => option?.TypeId === value?.TypeId}
                    onChange={(event, newValue) => field.onChange(newValue)}
                    value={field.value || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type"
                        size="small"
                        error={!!methods.formState.errors.type}
                        // helperText={methods.formState.errors.type?.message}
                      />
                    )}
                    />
                  )}
                /> */}
            <AutoComplete
              id="type"
              options={
                ticketTypesByCustomer?.isPending
                  ? []
                  : ticketTypesByCustomer?.data?.map((data: any) => ({ //todo add a type
                    id: data.TypeId,
                    label: data.Name,
                  })) || []
              }
              // options={ [] }
              loading={ticketTypesByCustomer?.isPending}
              autocompleteProps={{
                id: 'type',
                loadingText: 'Loading Types...',
                size: 'small'
              }}
              multiple={false}
              label="Type"
              required
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            {/* <Controller
                  name="priority"
                  control={methods.control}
                  render={({ field }) => (
                    <Autocomplete
                    {...field}
                    options={priorities && priorities?.data || []}
                    getOptionLabel={(option: any) => option.Name}
                    isOptionEqualToValue={(option, value) => option?.PriorityID === value?.PriorityID}
                    onChange={(event, newValue) => field.onChange(newValue)}
                    value={field.value || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Priority"
                        size="small"
                        error={!!methods.formState.errors.priority}
                        // helperText={methods.formState.errors.priority?.message}
                      />
                    )}
                    />
                  )}
                /> */}
            <AutoComplete
              id="priority"
              options={
                priorities?.isPending
                  ? []
                  : priorities?.data?.map((data: any) => ({ //todo add a type
                    id: data.PriorityID,
                    label: data.Name,
                  })) || []
              }
              // options={ [] }
              loading={priorities?.isPending}
              autocompleteProps={{
                id: 'priority',
                loadingText: 'Loading Priorities...',
                size: 'small'
              }}
              multiple={false}
              label="Priority"
              required
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <Controller
              name="status"
              control={methods.control}
              render={({ field }) => {
                // console.log('field:', field)
                const backgroundColor = field.value ? getColor(field.value.Color) : 'white';
                const textColor = field.value ? getTextColor(backgroundColor) : 'black';
                return (
                  <Autocomplete
                    // {...field}
                    options={statusesByCustomer && statusesByCustomer?.data || []}
                    getOptionLabel={(option: any) => option.Name}
                    isOptionEqualToValue={(option, value) => {
                      // console.log('status isOptionEqualToValue option:', option)
                      // console.log('status isOptionEqualToValue value:', value)
                      return option?.StatusId === value?.StatusId
                    }}
                    onChange={(event, newValue) => {
                      // console.log('status newValue:', newValue)
                      if (newValue) {
                        // Only destructure if newValue is not null
                        const { StatusId, Name, Color } = newValue;
                        field.onChange({ StatusId, Name, Color }); // Send only the necessary fields
                      } else {
                        field.onChange(null); // Handle the case when no value is selected
                      }
                    }}
                    value={field.value ? { StatusId: field.value.StatusId, Name: field.value.Name, Color: field.value.Color } : null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Status"
                        size="small"
                        error={!!methods.formState.errors.status}
                        // helperText={methods.formState.errors.status?.message}
                        sx={{
                          '& .MuiOutlinedInput-input': {
                            backgroundColor: backgroundColor && `${backgroundColor} !important`,
                            color: textColor && `${textColor} !important`,
                            borderRadius: '4px'
                          },
                        }}
                      />
                    )}
                  />
                )
              }}
            />
            {/* <AutoComplete
              id="status"
              options={
                statusesByCustomer?.isPending
                  ? []
                  : statusesByCustomer?.data?.map((data: any) => ({ //todo add a type
                    id: data.StatusId,
                    label: data.Name,
                  })) || []
              }
              // options={ [] }
              loading={statusesByCustomer?.isPending}
              autocompleteProps={{
                id: 'status',
                loadingText: 'Loading Statuses...',
                size: 'small',
                // sx: {
                //   '& .MuiOutlinedInput-input': {
                //     backgroundColor: selectedStatus ? getColor(selectedStatus.Color) : 'white',
                //     color: selectedStatus ? getTextColor(getColor(selectedStatus.Color)) : 'black',
                //   },
                // },
              }}
              multiple={false}
              label="Status"
              required
            /> */}
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <Controller
              name="dueDate"
              control={control}
              // defaultValue={null}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  // name='dueDate'
                  label="Due Date"
                  value={field.value}
                  onChange={(newValue) => field.onChange(newValue)} // Update form field on date change
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small',
                      // label: 'Due Date',
                      InputProps: {
                        sx: { color: field.value && field.value.isBefore(dayjs()) ? 'red' : 'black' },  // Change color based on value field.value.isBefore
                      },
                    },
                  }}
                />
              )}
            />

            {/* <MuiDatePicker
              id="dueDate"
              name="dueDate"
              label="Due Date"
              // variant="outlined"
              placeholder="Select Due Date..."
              // required
              control={control}
              fullWidth
              inputProps={{ size: 'small' }}
            /> */}
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 9 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            {/* <Controller
                  name="assignee"
                  control={methods.control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={agentsByCustomer && agentsByCustomer?.data?.VisibleAgents || []} //maybe add in .isSuccess
                      getOptionLabel={(option: any) => option.Name}
                      isOptionEqualToValue={(option, value) => option?.AgentId === value?.AgentId}
                      onChange={(event, newValue) => {
                        console.log(' assignee newValue:', newValue)
                        field.onChange(newValue)}}
                      value={field.value || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assigned To"
                          size="small"
                          error={!!methods.formState.errors.assignee}
                          // helperText={methods.formState.errors.assignee?.message}
                        />
                      )}
                    />
                  )}
                /> */}
            <AutoComplete
              id="assignee"
              options={
                agentsByCustomer?.isPending
                  ? []
                  : agentsByCustomer?.data?.VisibleAgents?.map((data: any) => ({ //todo add a type
                    id: data.AgentId,
                    label: data.Name,
                  })) || []
              }
              // options={ [] }
              loading={agentsByCustomer?.isPending}
              autocompleteProps={{
                id: 'assignee',
                loadingText: 'Loading Assignees...',
                size: 'small'
              }}
              multiple={false}
              label="Assignee"
              required
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 6, md: 3 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            <Button id='assign-to-me' variant='outlined' onClick={handleAssignToMeClick}>Assign To Me</Button>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            {/* <Controller
                  name="clientName"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id='clientName'
                      placeholder="Client Name"
                      label="Client Name"
                      value={field.value || ''}
                      required
                      size='small'
                      fullWidth
                      error={!!methods.formState.errors.clientName}
                      // helperText={methods.formState.errors.clientName?.message}
                    />  
                  )}
                /> */}
            <TextBox
              name="clientName"
              control={control}
              label="Client Name"
              required
              size="small"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            {/* <Controller
                  name="clientEmail"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id='clientEmail'
                      placeholder="Client Email"
                      label="Client Email"
                      value={field.value || ''}
                      required
                      size='small'
                      fullWidth
                      error={!!methods.formState.errors.clientEmail}
                      // helperText={methods.formState.errors.clientEmail?.message}
                    />  
                  )}
                /> */}
            <TextBox
              name="clientEmail"
              control={control}
              label="Client Email"
              required
              size="small"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
            {/* <Controller
                  name="clientPhone"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id='clientPhone'
                      placeholder="Client Phone"
                      label="Client Phone"
                      value={field.value || ''}
                      required
                      size='small'
                      fullWidth
                      error={!!methods.formState.errors.clientPhone}
                      // helperText={methods.formState.errors.clientPhone?.message}
                    />  
                  )}
                /> */}
            <TextBox
              name="clientPhone"
              control={control}
              label="Client Phone"
              required
              size="small"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid size={12}>
          <Box marginY={1} gap={1.5} sx={{ width: "100%" }} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
            {/* <Controller
                  name="clientAddress"
                  control={methods.control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id='clientAddress'
                      placeholder="Client Address"
                      label="Client Address"
                      value={field.value || ''}
                      required
                      size='small'
                      fullWidth
                      error={!!methods.formState.errors.clientAddress}
                      // helperText={methods.formState.errors.clientAddress?.message}
                    />  
                  )}
                /> */}
            <Typography fontSize={11} color="gray">
              Lat: {ticketById.data?.Latitude}, Lon: {ticketById.data?.Longitude}
              <Tooltip title="Copy to clipboard" placement='top'>
                <IconButton size="small" sx={{ p: 0, ml: 1 }} onClick={handleLatLonCopy} >
                  <ContentCopyIcon sx={{ fontSize: '0.8rem' }} />
                </IconButton>
              </Tooltip>
            </Typography>
            <TextBox
              name="clientAddress"
              control={control}
              label="Client Address"
              required
              size="small"
              fullWidth
            />
          </Box>
        </Grid>
      </Grid >
    </Grid >
    // </form>
  )
}
