/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as AuthImport } from './routes/_auth'
import { Route as AuthIndexImport } from './routes/_auth/index'
import { Route as AuthWorkflowRulesImport } from './routes/_auth/workflow-rules'
import { Route as AuthTypeEditorImport } from './routes/_auth/type-editor'
import { Route as AuthTemplatesImport } from './routes/_auth/templates'
import { Route as AuthStatusEditorImport } from './routes/_auth/status-editor'
import { Route as AuthReportsImport } from './routes/_auth/reports'
import { Route as AuthReleaseNotesImport } from './routes/_auth/release-notes'
import { Route as AuthFieldGroupsImport } from './routes/_auth/field-groups'
import { Route as AuthCustomersImport } from './routes/_auth/customers'
import { Route as AuthCustomFieldsImport } from './routes/_auth/custom-fields'
import { Route as AuthBatchUpdatesImport } from './routes/_auth/batch-updates'
import { Route as AuthAgentsImport } from './routes/_auth/agents'
import { Route as AuthTicketTicketIdImport } from './routes/_auth/ticket/$ticketId'
import { Route as AuthStatusEditorStatusIdImport } from './routes/_auth/status-editor/$statusId'

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthWorkflowRulesRoute = AuthWorkflowRulesImport.update({
  id: '/workflow-rules',
  path: '/workflow-rules',
  getParentRoute: () => AuthRoute,
} as any)

const AuthTypeEditorRoute = AuthTypeEditorImport.update({
  id: '/type-editor',
  path: '/type-editor',
  getParentRoute: () => AuthRoute,
} as any)

const AuthTemplatesRoute = AuthTemplatesImport.update({
  id: '/templates',
  path: '/templates',
  getParentRoute: () => AuthRoute,
} as any)

const AuthStatusEditorRoute = AuthStatusEditorImport.update({
  id: '/status-editor',
  path: '/status-editor',
  getParentRoute: () => AuthRoute,
} as any)

const AuthReportsRoute = AuthReportsImport.update({
  id: '/reports',
  path: '/reports',
  getParentRoute: () => AuthRoute,
} as any)

const AuthReleaseNotesRoute = AuthReleaseNotesImport.update({
  id: '/release-notes',
  path: '/release-notes',
  getParentRoute: () => AuthRoute,
} as any)

const AuthFieldGroupsRoute = AuthFieldGroupsImport.update({
  id: '/field-groups',
  path: '/field-groups',
  getParentRoute: () => AuthRoute,
} as any)

const AuthCustomersRoute = AuthCustomersImport.update({
  id: '/customers',
  path: '/customers',
  getParentRoute: () => AuthRoute,
} as any)

const AuthCustomFieldsRoute = AuthCustomFieldsImport.update({
  id: '/custom-fields',
  path: '/custom-fields',
  getParentRoute: () => AuthRoute,
} as any)

const AuthBatchUpdatesRoute = AuthBatchUpdatesImport.update({
  id: '/batch-updates',
  path: '/batch-updates',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAgentsRoute = AuthAgentsImport.update({
  id: '/agents',
  path: '/agents',
  getParentRoute: () => AuthRoute,
} as any)

const AuthTicketTicketIdRoute = AuthTicketTicketIdImport.update({
  id: '/ticket/$ticketId',
  path: '/ticket/$ticketId',
  getParentRoute: () => AuthRoute,
} as any)

const AuthStatusEditorStatusIdRoute = AuthStatusEditorStatusIdImport.update({
  id: '/$statusId',
  path: '/$statusId',
  getParentRoute: () => AuthStatusEditorRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_auth/agents': {
      id: '/_auth/agents'
      path: '/agents'
      fullPath: '/agents'
      preLoaderRoute: typeof AuthAgentsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/batch-updates': {
      id: '/_auth/batch-updates'
      path: '/batch-updates'
      fullPath: '/batch-updates'
      preLoaderRoute: typeof AuthBatchUpdatesImport
      parentRoute: typeof AuthImport
    }
    '/_auth/custom-fields': {
      id: '/_auth/custom-fields'
      path: '/custom-fields'
      fullPath: '/custom-fields'
      preLoaderRoute: typeof AuthCustomFieldsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/customers': {
      id: '/_auth/customers'
      path: '/customers'
      fullPath: '/customers'
      preLoaderRoute: typeof AuthCustomersImport
      parentRoute: typeof AuthImport
    }
    '/_auth/field-groups': {
      id: '/_auth/field-groups'
      path: '/field-groups'
      fullPath: '/field-groups'
      preLoaderRoute: typeof AuthFieldGroupsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/release-notes': {
      id: '/_auth/release-notes'
      path: '/release-notes'
      fullPath: '/release-notes'
      preLoaderRoute: typeof AuthReleaseNotesImport
      parentRoute: typeof AuthImport
    }
    '/_auth/reports': {
      id: '/_auth/reports'
      path: '/reports'
      fullPath: '/reports'
      preLoaderRoute: typeof AuthReportsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/status-editor': {
      id: '/_auth/status-editor'
      path: '/status-editor'
      fullPath: '/status-editor'
      preLoaderRoute: typeof AuthStatusEditorImport
      parentRoute: typeof AuthImport
    }
    '/_auth/templates': {
      id: '/_auth/templates'
      path: '/templates'
      fullPath: '/templates'
      preLoaderRoute: typeof AuthTemplatesImport
      parentRoute: typeof AuthImport
    }
    '/_auth/type-editor': {
      id: '/_auth/type-editor'
      path: '/type-editor'
      fullPath: '/type-editor'
      preLoaderRoute: typeof AuthTypeEditorImport
      parentRoute: typeof AuthImport
    }
    '/_auth/workflow-rules': {
      id: '/_auth/workflow-rules'
      path: '/workflow-rules'
      fullPath: '/workflow-rules'
      preLoaderRoute: typeof AuthWorkflowRulesImport
      parentRoute: typeof AuthImport
    }
    '/_auth/': {
      id: '/_auth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/status-editor/$statusId': {
      id: '/_auth/status-editor/$statusId'
      path: '/$statusId'
      fullPath: '/status-editor/$statusId'
      preLoaderRoute: typeof AuthStatusEditorStatusIdImport
      parentRoute: typeof AuthStatusEditorImport
    }
    '/_auth/ticket/$ticketId': {
      id: '/_auth/ticket/$ticketId'
      path: '/ticket/$ticketId'
      fullPath: '/ticket/$ticketId'
      preLoaderRoute: typeof AuthTicketTicketIdImport
      parentRoute: typeof AuthImport
    }
  }
}

// Create and export the route tree

interface AuthStatusEditorRouteChildren {
  AuthStatusEditorStatusIdRoute: typeof AuthStatusEditorStatusIdRoute
}

const AuthStatusEditorRouteChildren: AuthStatusEditorRouteChildren = {
  AuthStatusEditorStatusIdRoute: AuthStatusEditorStatusIdRoute,
}

const AuthStatusEditorRouteWithChildren =
  AuthStatusEditorRoute._addFileChildren(AuthStatusEditorRouteChildren)

interface AuthRouteChildren {
  AuthAgentsRoute: typeof AuthAgentsRoute
  AuthBatchUpdatesRoute: typeof AuthBatchUpdatesRoute
  AuthCustomFieldsRoute: typeof AuthCustomFieldsRoute
  AuthCustomersRoute: typeof AuthCustomersRoute
  AuthFieldGroupsRoute: typeof AuthFieldGroupsRoute
  AuthReleaseNotesRoute: typeof AuthReleaseNotesRoute
  AuthReportsRoute: typeof AuthReportsRoute
  AuthStatusEditorRoute: typeof AuthStatusEditorRouteWithChildren
  AuthTemplatesRoute: typeof AuthTemplatesRoute
  AuthTypeEditorRoute: typeof AuthTypeEditorRoute
  AuthWorkflowRulesRoute: typeof AuthWorkflowRulesRoute
  AuthIndexRoute: typeof AuthIndexRoute
  AuthTicketTicketIdRoute: typeof AuthTicketTicketIdRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthAgentsRoute: AuthAgentsRoute,
  AuthBatchUpdatesRoute: AuthBatchUpdatesRoute,
  AuthCustomFieldsRoute: AuthCustomFieldsRoute,
  AuthCustomersRoute: AuthCustomersRoute,
  AuthFieldGroupsRoute: AuthFieldGroupsRoute,
  AuthReleaseNotesRoute: AuthReleaseNotesRoute,
  AuthReportsRoute: AuthReportsRoute,
  AuthStatusEditorRoute: AuthStatusEditorRouteWithChildren,
  AuthTemplatesRoute: AuthTemplatesRoute,
  AuthTypeEditorRoute: AuthTypeEditorRoute,
  AuthWorkflowRulesRoute: AuthWorkflowRulesRoute,
  AuthIndexRoute: AuthIndexRoute,
  AuthTicketTicketIdRoute: AuthTicketTicketIdRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthRouteWithChildren
  '/login': typeof LoginRoute
  '/agents': typeof AuthAgentsRoute
  '/batch-updates': typeof AuthBatchUpdatesRoute
  '/custom-fields': typeof AuthCustomFieldsRoute
  '/customers': typeof AuthCustomersRoute
  '/field-groups': typeof AuthFieldGroupsRoute
  '/release-notes': typeof AuthReleaseNotesRoute
  '/reports': typeof AuthReportsRoute
  '/status-editor': typeof AuthStatusEditorRouteWithChildren
  '/templates': typeof AuthTemplatesRoute
  '/type-editor': typeof AuthTypeEditorRoute
  '/workflow-rules': typeof AuthWorkflowRulesRoute
  '/': typeof AuthIndexRoute
  '/status-editor/$statusId': typeof AuthStatusEditorStatusIdRoute
  '/ticket/$ticketId': typeof AuthTicketTicketIdRoute
}

export interface FileRoutesByTo {
  '/login': typeof LoginRoute
  '/agents': typeof AuthAgentsRoute
  '/batch-updates': typeof AuthBatchUpdatesRoute
  '/custom-fields': typeof AuthCustomFieldsRoute
  '/customers': typeof AuthCustomersRoute
  '/field-groups': typeof AuthFieldGroupsRoute
  '/release-notes': typeof AuthReleaseNotesRoute
  '/reports': typeof AuthReportsRoute
  '/status-editor': typeof AuthStatusEditorRouteWithChildren
  '/templates': typeof AuthTemplatesRoute
  '/type-editor': typeof AuthTypeEditorRoute
  '/workflow-rules': typeof AuthWorkflowRulesRoute
  '/': typeof AuthIndexRoute
  '/status-editor/$statusId': typeof AuthStatusEditorStatusIdRoute
  '/ticket/$ticketId': typeof AuthTicketTicketIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/login': typeof LoginRoute
  '/_auth/agents': typeof AuthAgentsRoute
  '/_auth/batch-updates': typeof AuthBatchUpdatesRoute
  '/_auth/custom-fields': typeof AuthCustomFieldsRoute
  '/_auth/customers': typeof AuthCustomersRoute
  '/_auth/field-groups': typeof AuthFieldGroupsRoute
  '/_auth/release-notes': typeof AuthReleaseNotesRoute
  '/_auth/reports': typeof AuthReportsRoute
  '/_auth/status-editor': typeof AuthStatusEditorRouteWithChildren
  '/_auth/templates': typeof AuthTemplatesRoute
  '/_auth/type-editor': typeof AuthTypeEditorRoute
  '/_auth/workflow-rules': typeof AuthWorkflowRulesRoute
  '/_auth/': typeof AuthIndexRoute
  '/_auth/status-editor/$statusId': typeof AuthStatusEditorStatusIdRoute
  '/_auth/ticket/$ticketId': typeof AuthTicketTicketIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/login'
    | '/agents'
    | '/batch-updates'
    | '/custom-fields'
    | '/customers'
    | '/field-groups'
    | '/release-notes'
    | '/reports'
    | '/status-editor'
    | '/templates'
    | '/type-editor'
    | '/workflow-rules'
    | '/'
    | '/status-editor/$statusId'
    | '/ticket/$ticketId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/login'
    | '/agents'
    | '/batch-updates'
    | '/custom-fields'
    | '/customers'
    | '/field-groups'
    | '/release-notes'
    | '/reports'
    | '/status-editor'
    | '/templates'
    | '/type-editor'
    | '/workflow-rules'
    | '/'
    | '/status-editor/$statusId'
    | '/ticket/$ticketId'
  id:
    | '__root__'
    | '/_auth'
    | '/login'
    | '/_auth/agents'
    | '/_auth/batch-updates'
    | '/_auth/custom-fields'
    | '/_auth/customers'
    | '/_auth/field-groups'
    | '/_auth/release-notes'
    | '/_auth/reports'
    | '/_auth/status-editor'
    | '/_auth/templates'
    | '/_auth/type-editor'
    | '/_auth/workflow-rules'
    | '/_auth/'
    | '/_auth/status-editor/$statusId'
    | '/_auth/ticket/$ticketId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/login"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/agents",
        "/_auth/batch-updates",
        "/_auth/custom-fields",
        "/_auth/customers",
        "/_auth/field-groups",
        "/_auth/release-notes",
        "/_auth/reports",
        "/_auth/status-editor",
        "/_auth/templates",
        "/_auth/type-editor",
        "/_auth/workflow-rules",
        "/_auth/",
        "/_auth/ticket/$ticketId"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_auth/agents": {
      "filePath": "_auth/agents.tsx",
      "parent": "/_auth"
    },
    "/_auth/batch-updates": {
      "filePath": "_auth/batch-updates.tsx",
      "parent": "/_auth"
    },
    "/_auth/custom-fields": {
      "filePath": "_auth/custom-fields.tsx",
      "parent": "/_auth"
    },
    "/_auth/customers": {
      "filePath": "_auth/customers.tsx",
      "parent": "/_auth"
    },
    "/_auth/field-groups": {
      "filePath": "_auth/field-groups.tsx",
      "parent": "/_auth"
    },
    "/_auth/release-notes": {
      "filePath": "_auth/release-notes.tsx",
      "parent": "/_auth"
    },
    "/_auth/reports": {
      "filePath": "_auth/reports.tsx",
      "parent": "/_auth"
    },
    "/_auth/status-editor": {
      "filePath": "_auth/status-editor.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/status-editor/$statusId"
      ]
    },
    "/_auth/templates": {
      "filePath": "_auth/templates.tsx",
      "parent": "/_auth"
    },
    "/_auth/type-editor": {
      "filePath": "_auth/type-editor.tsx",
      "parent": "/_auth"
    },
    "/_auth/workflow-rules": {
      "filePath": "_auth/workflow-rules.tsx",
      "parent": "/_auth"
    },
    "/_auth/": {
      "filePath": "_auth/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/status-editor/$statusId": {
      "filePath": "_auth/status-editor/$statusId.tsx",
      "parent": "/_auth/status-editor"
    },
    "/_auth/ticket/$ticketId": {
      "filePath": "_auth/ticket/$ticketId.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
