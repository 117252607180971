import axios, { AxiosError, AxiosResponse } from 'axios';

declare module 'axios' {
  export interface AxiosRequestConfig {
    onError?: Function;
    onSuccess?: Function;
    options?: {
      ignoreAbortController?: boolean;
    };
    id?: string;
  }
}

const axiosInstance = axios.create();
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
// axiosInstance.defaults.headers.common['ngrok-skip-browser-warning'] = true;
axiosInstance.defaults.withCredentials = true;

//? Contains all the request ids and abort controllers related with them
const requestAbortControllers: any = {};

const abortRequest = (requestId: string) => {
  if (requestAbortControllers[requestId]) {
    requestAbortControllers[requestId]?.abort();
  }
};

const getAuthToken = () => {
  const token = localStorage.getItem('jwt'); //? Change this to wherever we store the bearer token, if we are saving it in authState we could grab it from the authState localStorage item
  const key = localStorage.getItem('public_key');
  console.warn(token)
  console.warn(key)
  return {
    token: token,
    key: key
  };
};

//? Can pass clearAuth to this function and automatically call it in the interceptors.response error to log the user out and clear the global auth state
//? Add this to app.tsx or header or something that could pass in the clearAuth in the future
export const setupInterceptors = () => {
  axiosInstance.interceptors.request.use(
    async (config: any) => {
      const requestAbortId = `controller/${config.url}_${config.id}`;
      if (!config?.options?.ignoreAbortController && requestAbortControllers[requestAbortId]) {
        //? This will abort the request if the same request is called more than once.
        abortRequest(requestAbortId);
      }
      if (!config.headers) {
        config.headers = { 'ngrok-skip-browser-warning': true };
      }
      const {token, key} = await getAuthToken();
      config.headers['Authorization'] = token
      config.headers['PublicKey'] = key
      requestAbortControllers[requestAbortId] = new AbortController();
      if (requestAbortControllers[requestAbortId] !== undefined) {
        config.signal = requestAbortControllers[requestAbortId]?.signal;
      }
      return config;
    },
    (error: AxiosError) => {
      //? Add custom error logic here
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (res: AxiosResponse) => {
      // console.log("Response api service", res, res.config);
      if (res?.config?.onSuccess) {
        return res.config.onSuccess(res);
      }
      return res;
    },
    (err: AxiosError) => {
      //? Can add code here to check the authorization token expired date and automatically log the user out
      if (err?.config?.onError) {
        return err.config.onError(err);
      }
      return err;
    },
  );
};

setupInterceptors();

export const apiService = axiosInstance;
