import React, { useEffect } from 'react'
import DashboardLayout from '@/layouts/DashboardLayout'
import { Accordion, AccordionSummary, Box, Button, Grid, Paper, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib'
import CustomCard from '@/components/Atoms/Card/Card.component'
import { useNavigate, useParams } from '@tanstack/react-router'
import { FormProvider, useForm } from 'react-hook-form'
import useTicket from '@/hooks/fetches/useTicket.service'
import useTicketList from '@/contexts/stores/ticketList.store'
import dayjs from 'dayjs'
import TicketDetailMap from '@/components/Organisms/TicketDetailMap.component'
import BaseInformation from './BaseInformation.page'
import { AccordionDetails, useMediaQuery, useTheme } from '@mui/material'
import { BaseInformationSchema2, defaultValues, schema } from '@/interfaces/schemas/baseInformation.schema'
import { zodResolver } from '@hookform/resolvers/zod'
import CustomFields, { CFTypeIdDetail } from './CustomFields.page'
import Attachments from './Attachments.page'
import Notes from './Notes.page'
import TicketHistory from './TicketHistory.page'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

interface TicketData {
  description?: string;
  type?: { id: number; label: string } | null;
  priority?: { id: number; label: string } | null;
  status?: { StatusId: number; Name: string; Color?: any } | null;
  dueDate?: dayjs.Dayjs | null;  // Update this to allow Dayjs objects
  assignee?: { id: number; label: string } | null;
  clientName?: string;
  clientEmail?: string;
  clientPhone?: string;
  clientAddress?: string;
}

interface CustomFieldValue {
  TicketId: number;
  Cid: number;
  TypeId: number;
  V: {
    MyInt?: number;
    SelectedOpt?: string | number;
    Str?: string;
    Checked?: boolean;
    TimeVal?: string | null;
  };
}

interface Result {
  baseFields: {
    TId: number;
    Title?: string;
    TypeId?: number;
    Priority?: string;
    StatusId?: number;
    Assignee?: number;
    DueDate?: string;
    ClientName?: string;
    ClientEmail?: string;
    ClientPhone?: string;
    ClientAddress?: string;
  };
  customFields: CustomFieldValue[];
}

const TicketDetail = () => {
  const navigate = useNavigate();
  const methods = useForm<BaseInformationSchema2>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues,
  });
  const { isDirty } = methods.formState;
  const { ticketListState, updateTicketList } = useTicketList();
  const { ticketId } = useParams({
    from: '/_auth/ticket/$ticketId',
  })
  // const { ticketId } = useParams({
  //   from: '/tickets/:ticketId',
  // });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  // console.log('ticketListState:', ticketListState);

  const ticketId2 = Number(ticketId)
  const {
    ticketById,
    ticketViewers,
    allTickets,
    customFields,
    statusesByCustomer,
    ticketTypesByCustomer,
    priorities,
    agentsByCustomer
  } = useTicket(
    ticketId2,
    ticketListState?.selectedCustomer?.id,
    ticketListState?.selectedCategory?.label
  );
  // const { data: ticketData } = ticketById || {};
  // const { data: ticketViewersData } = ticketViewers || {};
  // const { data: allTicketsData } = allTickets || {};
  // const { data: statusesByCustomerData } = statusesByCustomer || {};
  // const { data: ticketTypesByCustomerData } = ticketTypesByCustomer || {};
  // const { data: prioritiesData } = priorities || {};
  // const { data: agentsByCustomerData } = agentsByCustomer || {};

  const { duplicateAddress, updateTicketBaseFields, updateTicketCustomFields } = useTicket(ticketId2, undefined, undefined, ticketById.data?.Ticket?.ClientAddress); //todo switch this over into its own hook and not the useTicket Hook
  // const { data: duplicateAddressData } = duplicateAddress || {};

  // console.log('duplicateAddress.data:', duplicateAddress?.data);


  // console.log('statusesByCustomerData:', statusesByCustomerData)

  // console.log('ticketById.data:', ticketById.data);
  // console.log('ticketViewersData:', ticketViewersData);
  // console.log('allTickets.data:', allTickets.data);

  const ticketNumber = () => {
    const index = allTickets?.data?.findIndex((ticket: any) => ticket.TicketExt.Ticket.TId === ticketListState?.selectedTicket?.id);
    if (index === undefined) return 0
    if (index !== -1) return allTickets?.data?.length - index;
  }

  const handleBackToTicketList = () => {
    if (isDirty) {
      const confirmLeave = confirm("You have unsaved changes, are you sure you want to leave?");
      if (confirmLeave) navigate({ to: '/' });
    } else {
      navigate({ to: '/' });
    }
  };

  // const prepareTicketData = (data: TicketData): Result => {
  //   const result: Result = {
  //     TId: ticketId2
  //   };

  //   Object.keys(data).forEach((key) => {
  //     const value = data[key as keyof TicketData];

  //     if (key === 'description' && value) {
  //       result['Title'] = value as string;

  //     } else if (key === 'type' && value && (value as { id: number }).id !== undefined) {
  //       result['TypeId'] = (value as { id: number }).id;

  //     } else if (key === 'priority' && value && (value as { label: string }).label) {
  //       result['Priority'] = (value as { label: string }).label.toLowerCase();

  //     } else if (key === 'status' && value && (value as { StatusId: number }).StatusId !== undefined) {
  //       result['StatusId'] = (value as { StatusId: number }).StatusId;

  //     } else if (key === 'assignee' && value && (value as { id: number }).id !== undefined) {
  //       result['Assignee'] = (value as { id: number }).id;

  //     } else if (key === 'dueDate' && value && dayjs.isDayjs(value)) {
  //       result['DueDate'] = value.format('YYYY-MM-DDTHH:mm:ss[Z]');

  //     } else if (key === 'clientName' && value) {
  //       result['ClientName'] = value as string;

  //     } else if (key === 'clientEmail' && value) {
  //       result['ClientEmail'] = value as string;

  //     } else if (key === 'clientPhone' && value) {
  //       result['ClientPhone'] = value as string;

  //     } else if (key === 'clientAddress' && value) {
  //       result['ClientAddress'] = value as string;
  //     } else {
  //       console.log('must be a custom field');
  //       console.log('key:', key);
  //       console.log('value:', value);
  //     }
  //   });

  //   return result;
  // }

  const prepareTicketData = (data: TicketData): Result => {
    const result: Result = {
      baseFields: {
        TId: ticketId2, // TId at the top of baseFields object
      },
      customFields: []  // customFields array for custom field objects
    };

    Object.keys(data).forEach((key) => {
      const value = data[key as keyof TicketData];

      // Check for base fields and populate baseFields object
      if (key === 'description' && value) {
        result.baseFields['Title'] = value as string;

      } else if (key === 'type' && value && (value as { id: number }).id !== undefined) {
        result.baseFields['TypeId'] = (value as { id: number }).id;

      } else if (key === 'priority' && value && (value as { label: string }).label) {
        result.baseFields['Priority'] = (value as { label: string }).label.toLowerCase();

      } else if (key === 'status' && value && (value as { StatusId: number }).StatusId !== undefined) {
        result.baseFields['StatusId'] = (value as { StatusId: number }).StatusId;

      } else if (key === 'assignee' && value && (value as { id: number }).id !== undefined) {
        result.baseFields['Assignee'] = (value as { id: number }).id;

      } else if (key === 'dueDate' && value && dayjs.isDayjs(value)) {
        result.baseFields['DueDate'] = value.format('YYYY-MM-DDTHH:mm:ss[Z]');

      } else if (key === 'clientName' && value) {
        result.baseFields['ClientName'] = value as string;

      } else if (key === 'clientEmail' && value) {
        result.baseFields['ClientEmail'] = value as string;

      } else if (key === 'clientPhone' && value) {
        result.baseFields['ClientPhone'] = value as string;

      } else if (key === 'clientAddress' && value) {
        result.baseFields['ClientAddress'] = value as string;

      } else {
        // Handle custom fields by looking them up in customFields collection
        // console.log('must be a custom field');
        // console.log('key:', key);
        // console.log('value:', value);

        // Find the corresponding custom field
        const customField = customFields?.data.find(
          (field: any) => field.ValueMeta.C.Meta.Cid.toString() === key
        );

        if (customField) {
          const customFieldObj = {
            TicketId: ticketId2,  // TicketId at the top of each custom field object
            Cid: customField.ValueMeta.C.Meta.Cid,
            TypeId: customField.ValueMeta.C.TypeId,
            V: {}
          };

          // Depending on the type of custom field, set the correct value
          if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTInteger') {
            customFieldObj.V = { MyInt: Number(value) };

          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTDropdown') {
            if (value && typeof value !== 'string' && 'label' in value) {
              customFieldObj.V = { SelectedOpt: value.label };
            }

          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTString') {
            customFieldObj.V = { Str: value };

          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTToggle') {
            customFieldObj.V = { Checked: value };

          } else if (CFTypeIdDetail(customField.ValueMeta.C.TypeId).ClassBinding === 'cfTDate') {
            // Ensure value is a valid date before passing to dayjs
            const dateValue = typeof value === 'string' || dayjs.isDayjs(value) ? value : null;

            customFieldObj.V = {
              TimeVal: dateValue ? dayjs(dateValue).format('YYYY-MM-DDTHH:mm') : null
            };
          }


          // Push the custom field object to the customFields array
          result.customFields.push(customFieldObj);
        }
      }
    });

    return result;
  };


  const onSubmit = async (data: any) => {
    // console.log("All form values before submit:", methods.getValues());
    const methodValues = methods.getValues()
    // console.log("Form submitted with values: ", data);

    const dirtyFields = Object.keys(methods.formState.dirtyFields);
    console.log("Dirty Fields: ", dirtyFields);
    const dirtyValues = dirtyFields.reduce((acc, fieldName) => {
      // console.log('acc:', acc);
      // console.log('fieldName:', fieldName);
      (acc as { [key: string]: any })[fieldName] = methodValues[fieldName as keyof BaseInformationSchema2];
      return acc;
    }, {} as Partial<BaseInformationSchema2>);

    console.log("Dirty Values: ", dirtyValues);
    const preparedData = prepareTicketData(dirtyValues);
    console.log(preparedData);
    if (dirtyFields.length > 0) {
      // console.log("Form submitted with preparedData: ", preparedData);
      try {
        if (Object.keys(preparedData.baseFields).length > 1) {
          await updateTicketBaseFields({ actionType: 'updateTicketBaseFields', data: preparedData.baseFields });
        }
        if (preparedData.customFields.length > 0) {
          await updateTicketCustomFields({ actionType: 'updateTicketCustomFields', data: preparedData.customFields });
        }
        // console.log('TicketDetail methods.reset')
        methods.reset(methodValues);
      } catch (error) {
        console.error("Error updating ticket:", error);
      }
    }
  };

  return (
    // <DashboardLayout>
    <>
      <Grid container display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
        <Grid xs={12} sx={{ marginBottom: "16px" }}>
          <CustomCard width="100%" children={
            <Grid container spacing={2}>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <Typography variant="h5" component="h5" sx={{ marginTop: 2 }}>
                  {ticketById?.data?.Ticket?.ClientAddress || ""}
                </Typography>
                <Typography variant="body1" component="p" display="flex" alignItems="center">
                  {/* <Grid item xs={12} container justifyContent="flex-end"> */}
                  <Button id="prev-ticket">
                    Previous
                  </Button>
                  <Typography sx={{ padding: '8px' }}>{ticketNumber()} of {allTickets?.data ? allTickets?.data?.length : 0}</Typography>
                  <Button id="next-ticket">
                    Next
                  </Button>
                  {/* </Grid> */}
                </Typography>
              </Grid>
              {/* <Typography variant="body1" component="p" display="flex" alignItems="center"> */}
              <Grid xs={12} container justifyContent="space-between" alignItems="center" sx={{ paddingLeft: '8px' }}>
                <Typography variant="body1" component="p">
                  Ticket: #{ticketById.data?.Ticket?.TId} - {ticketById.data?.CustomerEntity?.EntName}
                </Typography>
                <Typography variant="body1" component="p" align="right" sx={{ paddingRight: '16px' }}>
                  {ticketViewers?.data?.length} Viewing: ({ticketViewers?.data?.map((agent: any) => agent.Name).join(', ')})
                </Typography>
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                {/* <Grid> */}
                <Typography variant="body1" component="p">
                  Last Updated {dayjs(ticketById.data?.Ticket.LastUpdated).format('MM/DD/YY')} by {ticketById.data?.Ticket.LastUpdatedByName} (Created on {dayjs(ticketById.data?.Ticket?.CreateTime).format('MM/DD/YY')})
                </Typography>
                {/* <Typography variant="body1" component="p">
                    Created: {dayjs(ticketById.data?.Ticket?.CreateTime).format('MM/DD/YY')}
                  </Typography> */}
                {/* </Grid> */}
                {/* <Grid item xs={12} container justifyContent="flex-end"> */}
                <Typography>
                  <Button sx={{ marginRight: '8px' }} id="delete-ticket" color="error">
                    {/* //todo Set this button up */}
                    Delete Ticket
                  </Button>
                  <Button sx={{ marginRight: '8px' }} variant='outlined' id="print-ticket">
                    {/* //todo Set this button up */}
                    Print
                  </Button>
                  <Button sx={{ marginRight: '8px' }} variant='outlined' id="map-ticket" onClick={() => updateTicketList({ ticketDetailMapOpen: true })}>
                    {/* //todo Finish the map. I think the data will be best to be pulled from the DOM rather than using state */}

                    Map
                  </Button>
                  <Button sx={{ marginRight: '8px' }} variant='outlined' id="return-home" onClick={handleBackToTicketList}>
                    {isSmallScreen ? "Back" : "Back To Ticket List"}
                  </Button>
                  <Button variant='contained' id="save-ticket" type='submit' onClick={methods.handleSubmit(onSubmit)}>
                    {/* //todo Set this button up */}
                    Save
                  </Button>
                </Typography>
                {/* </Grid> */}
              </Grid>
              {/* </Typography> */}
            </Grid>
          } />
        </Grid>
        {duplicateAddress.data && (
          <Grid
            item
            xs={12}
            width="100%"
            id="duplicate-address-warning"
            style={{
              borderColor: 'gold',
              color: 'black',
              backgroundColor: 'yellow',
              maxHeight: '100px',
              minHeight: '52px',
              overflowY: 'auto',
              borderStyle: 'solid',
              borderRadius: '6px',
              marginBottom: '16px',
            }}
          >
            <Box sx={{ padding: '16px' }}>
              Warning - Tickets with a similar client address:{" "}
              {duplicateAddress?.data?.map((ticket: any, index: number) => (
                <Grid item key={ticket.TicketID} component="span" style={{ display: 'inline' }}>
                  <a href={`${ticket.TicketID}`} style={{ color: 'blue' }}>{ticket.Address}</a>
                  {index < duplicateAddress?.data?.length - 1 ? ', ' : ''}
                </Grid>
              ))}
            </Box>
          </Grid>
        )}
        <Grid display={"flex"} flexDirection={"row"} maxWidth={"100%"} >
          <Grid
            item
            xs={ticketListState.ticketHistoryOpen ? 12 : 11.5}
            sm={ticketListState.ticketHistoryOpen ? 8 : 11.5}
            className="baseInfo"
            display="flex"
            flexDirection="column"
            sx={{
              marginRight: 4,
              maxHeight: isSmallScreen ? 'calc(100vh - 262px)' : 'calc(100vh - 250px)',
              overflowX: 'auto',
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Accordion id="base-information" sx={{ marginBottom: 4, marginTop: 0.5, boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;", width: "99%", borderRadius: 1.5, '&.Mui-expanded:first-of-type': { marginTop: 0.5 } }} >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingX: 4 }}>Base Information</AccordionSummary>
                    <AccordionDetails sx={{ padding: 4 }}>
                      <BaseInformation />
                    </AccordionDetails>
                    {/* // statuses={statusesByCustomerData}
                  // types={ticketTypesByCustomerData}
                  // priorities={prioritiesData}
                  // agents={agentsByCustomerData}
                  // methods={methods}
                  // props={[]} */}
                  </Accordion>
                  <Accordion id="custom-fields" sx={{ marginBottom: 4, '&.Mui-expanded': { marginTop: '0px', }, '&::before': { display: 'none' }, boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;", width: "99%", borderRadius: 1.5, '&.Mui-expanded:last-of-type': { marginBottom: 4 } }} >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingX: 4 }} >Custom Fields</AccordionSummary>
                    <AccordionDetails sx={{ padding: 4 }}>
                      <CustomFields />
                    </AccordionDetails>
                    {/* <CustomFields {...props} /> */}
                    {/* <CustomFields2 {...props} /> */}
                    {/* <CustomFields3 /> */}
                    {/* CustomFields placeholder */}
                  </Accordion>

                </Grid>
                {/* <Accordion id="base-information" defaultExpanded={true} children={
                        <><BaseInformation {...ticketById.data} /><h1>homeTableColumns</h1></>
                    } /> */}
              </form>
            </FormProvider>
            <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Accordion id="attachments" sx={{ marginBottom: 4, '&.Mui-expanded': { marginTop: '0px' }, boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;", width: "99%", borderRadius: 1.5 }} >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingX: 4 }} >Attachments</AccordionSummary>
                <AccordionDetails sx={{ padding: 4 }}>
                  <Attachments />
                </AccordionDetails>
              </Accordion>
              <Accordion id="notes" sx={{ marginBottom: 4, '&.Mui-expanded': { marginTop: '0px' }, '&::before': { display: 'none' }, boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;", width: "99%", borderRadius: 1.5, '&.Mui-expanded:last-of-type': { marginBottom: 0.5 } }} >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingX: 4 }} >Notes</AccordionSummary>
                <AccordionDetails sx={{ padding: 4 }}>
                  <Notes />
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* <Accordion title="Attachments" > */}
            {/* <Attachments /> */}
            {/* Attachments */}
            {/* </Accordion> */}
            {/* <Accordion title="Notes" > */}
            {/* <Notes notes={notes} /> */}
            {/* </Accordion> */}
          </Grid>
          {ticketListState.ticketHistoryOpen &&
            <Grid item xs={12} sm={4}
              sx={{ overflowY: 'auto', borderRadius: 1.5, boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;" }}
            >
              <Paper>
                <Typography variant="body1" component="p">
                  <TicketHistory />
                </Typography>
                {/* <div dangerouslySetInnerHTML={{ __html: props?.TicketHistory }} /> */}
              </Paper>
            </Grid>
          }
          {!ticketListState.ticketHistoryOpen &&
            <Grid>
              <Button id='expand-btn' variant='outlined' onClick={() => updateTicketList({ ticketHistoryOpen: true })}><KeyboardDoubleArrowLeftIcon /></Button>
            </Grid>
          }
        </Grid>
      </Grid >

      {ticketListState.ticketDetailMapOpen && <TicketDetailMap />}
    </>
    // </DashboardLayout>
  )
}

export default TicketDetail