import { createRouter, RouterProvider } from '@tanstack/react-router';
import { AppRouter } from './app/router';
import { routeTree } from './routeTree.gen';
import { queryClient } from './lib/queryClient';
import { CircularProgress } from '@mui/material';
import { useAuth } from './lib/auth';

const router = createRouter({
  routeTree: routeTree,
  context: {
    auth: undefined!, // This will be set after we wrap the app in an AuthProvider
    handles: {},
    queryClient: queryClient
  },

  defaultPendingComponent: () => (
    <div>
      <CircularProgress />
    </div>
  ),
  // This make the loader only wait 200ms before showing the pending component, instead of the default 1000ms
  defaultPendingMs: 200,
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
const App = () => {
  const auth = useAuth()
  // return <AppRouter />;
  return <RouterProvider router={router} context={{ auth }} />
};

export default App;
