import React from 'react';
import { Chip, Container } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Link,
  Paper,
  Typography,
} from '@utilisourcepackagelibdev/utilisourcepackagelib';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ReleaseNotes = () => {
  interface ReleaseNote {
    version: string;
    date: string;
    features: string[];
    improvements: string[];
    bugFixes: string[];
  }

  const releaseNotes: ReleaseNote[] = [
    {
      version: 'v0.9.0',
      date: 'September 23, 2024',
      features: ['Added boilerplate'],
      improvements: [],
      bugFixes: [],
    },
    {
      version: 'v0.8.0',
      date: 'September 6, 2024',
      features: ['Added Release Notes Page'],
      improvements: [],
      bugFixes: [],
    },
  ];

  return (
    <Container
      maxWidth="md"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      <Typography variant="h2" component="h1" gutterBottom>
        Workflow Release Notes
      </Typography>
      {releaseNotes.map((note, index) => (
        <Paper key={note.version} elevation={3} sx={{ mb: 4, p: 3, width: '100%' }}>
          <Typography variant="h4" gutterBottom>
            {note.version}
            <Chip label={note.date} size="small" sx={{ ml: 2 }} />
          </Typography>
          <Divider sx={{ my: 2 }} />
          {note.features.length === 0 ? null : (
            <>
              <Typography variant="h6" gutterBottom>
                New Features
              </Typography>
              <ul>
                {note.features.map((feature, i) => (
                  <li key={i}>
                    <Typography>{feature}</Typography>
                  </li>
                ))}
              </ul>
            </>
          )}
          {note.improvements.length === 0 ? null : (
            <>
              <Typography variant="h6" gutterBottom>
                Improvements
              </Typography>
              <ul>
                {note.improvements.map((improvement, i) => (
                  <li key={i}>
                    <Typography>{improvement}</Typography>
                  </li>
                ))}
              </ul>
            </>
          )}
          {note.bugFixes.length === 0 ? null : (
            <>
              <Typography variant="h6" gutterBottom>
                Bug Fixes
              </Typography>
              <ul>
                {note.bugFixes.map((bugFix, i) => (
                  <li key={i}>
                    <Typography>{bugFix}</Typography>
                  </li>
                ))}
              </ul>
            </>
          )}
          {/* {index === 0 && (
                        <Box mt={2}>
                            <Typography variant="body2">
                                For a full list of changes, see the{' '}
                                <Link href="https://github.com/mui/material-ui/blob/master/CHANGELOG.md">
                                    changelog
                                </Link>
                                .
                            </Typography>
                        </Box>
                    )} */}
        </Paper>
      ))}
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Previous Releases</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Such empty.</Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default ReleaseNotes;
