import type { MRT_RowData, MRT_TableOptions } from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import React from 'react';
import { ReactTableDevtools } from '@tanstack/react-table-devtools';

interface IProps {
  table: any; //consumes useTableInstance hook
  children?: MRT_TableOptions<MRT_RowData>[] | any;
  belowTableChildren?: MRT_TableOptions<MRT_RowData>[] | any;
  showDevTools?: boolean;
}

const SimpleTable = (props: IProps) => {
  const { table } = props;

  return (
    <>
      <MaterialReactTable table={table} />
      {props.showDevTools && <ReactTableDevtools table={table} />}
    </>
  );
};

export default SimpleTable;
