import { Grid2 as Grid, Card, Paper, CardContent, Typography, Box, Autocomplete, AutocompleteRenderInputParams, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, DialogActions } from '@mui/material'
import { FormProvider, useFormContext } from 'react-hook-form'
import useTicketList from '@/contexts/stores/ticketList.store'
import useTicket from '@/hooks/fetches/useTicket.service'
import dayjs from 'dayjs';
import { AutoComplete, Button } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { Download, FileDownload, PageviewOutlined } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import { getAttachmentDownloadOptions, getAttachmentViewOptions } from '@/hooks/fetches/useAttachment.service';
import { number } from 'zod';
import { queryClient } from '@/contexts/globalStateProvider';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { set } from 'idb-keyval';


export default function Attachments() {

  const { ticketListState, updateTicketList } = useTicketList();
  const [downloadEnabled, setDownloadEnabled] = useState(false);
  // const [viewEnabled, setViewEnabled] = useState(false);
  const [fid, setFid] = useState<number | null>(null);
  const [action, setAction] = useState<'download' | 'view' | null>(null);
  const [downloadAttachment, setDownloadAttachment] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File[]>([]);
  // const methods = useFormContext();
  // const { formState, control } = methods;

  // console.log('ticketListState:', ticketListState);

  const { attachmentsById, attachmentTypesByCustomer, updateTicketAttachments } = useTicket(ticketListState?.selectedTicket?.id, ticketListState?.selectedCustomer?.id);

  // const {
  //   attachmentByIdForDownload,
  //   attachmentByIdForView
  // } = useAttachment(fid || 0);
  // const attachmentByIdForDownload = useQuery(getAttachmentDownloadOptions(fid || 0, true))
  // const attachmentByIdForView = useQuery(getAttachmentViewOptions(fid || 0, true))

  const handleDialogCancel = () => {
    setIsDialogOpen(false);
    setSelectedFile([]);
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    //todo save the file
  }

  const ChooseFileBtn = (attachmentType: any) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const isThereAFile = (attachmentsById?.data && attachmentsById?.data || []).filter(
      (attachment: any) => attachment.AttachmentTypeId === attachmentType.attachmentType.ATID
    );

    const handleChooseFileClick = () => {
      fileInputRef.current?.click();
      // console.log('this should open the dialog 2')
      // setIsDialogOpen(true);
    };

    const handleFileChange = async (event: any) => {
      const selectedFiles = Array.from<File>(event.target.files || []);
      // const selectedFiles = event.target.files;
      setSelectedFile(selectedFiles)
      console.log("Selected files:", selectedFiles);
      // console.log("Selected files.FileList:", selectedFiles.FileList)
      // console.log(selectedFiles?.map((file: any) => { console.log('file:', file); return file.name }))
      console.log('attachmentType.attachmentType:', attachmentType.attachmentType)
      if (attachmentType.attachmentType.CatchAll) {
        console.log('this should open the dialog')
        setIsDialogOpen(true);
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      if (selectedFiles.length > 0) {
        // Use FormData to prepare the request
        const formData = new FormData();

        // Add each file to the FormData object
        selectedFiles.forEach((file) => {
          formData.append("attachment", file); // Append the file as "attachment"
        });

        // Add metadata to the FormData
        formData.append(
          "noteMetadata",
          JSON.stringify({
            attachmenttypeid: attachmentType.attachmentType.ATID,
            message: "",
            subject: "",
            ticketid: ticketListState?.selectedTicket?.id,
          })
        );

        console.log("formData:", formData);

        try {
          // Use your mutation to send the FormData
          const attachmentPost = await updateTicketAttachments({
            actionType: "updateTicketAttachments",
            data: formData, // Pass FormData to the mutation
          });

          console.log("attachmentPost:", attachmentPost);
        } catch (error) {
          console.error("Error uploading attachments:", error);
        }
      }
      //todo save the file
    };

    const showButton = (!attachmentType.attachmentType.Multiple && !isThereAFile.length) || attachmentType.attachmentType.Multiple;
    const buttonLabel = attachmentType.attachmentType.CatchAll ? "Choose File And Type" : "Choose File";

    return (
      <>
        {showButton && (
          <>
            <Button id="addAttachment" onClick={handleChooseFileClick}>
              {buttonLabel}
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
              multiple={attachmentType.attachmentType.Multiple}
            />
          </>
        )}

        {/* //? The list in the Autocomplete is all the Types that are in the CatchAll */}

      </>
    );
  };

  const handleDownloadClick = async (attachment: any) => {
    try {
      const potato = await queryClient.fetchQuery(getAttachmentDownloadOptions(attachment.Fid, true));
      console.log('potato:', potato);
      if (potato instanceof Blob) {
        const url = URL.createObjectURL(potato);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = downloadAttachment.Filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
      } else {
        console.error('Downloaded data is not a Blob.');
      }
    } catch (error) {
      console.error('Error fetching attachment:', error);
    }
    setFid(attachment.Fid);
    setDownloadAttachment(attachment);
    setAction('download');
  };

  const handleViewClick = async (Fid: number) => {
    try {
      const potato = await queryClient.fetchQuery(getAttachmentViewOptions(Fid, true));
      console.log('potato:', potato);
      if (potato instanceof Blob) {
        const url = URL.createObjectURL(potato);
        window.open(url, '_blank');
      } else {
        console.error('Viewed data is not a Blob.');
      }
    } catch (error) {
      console.error('Error fetching attachment:', error);
    }
    setFid(Fid);
    setAction('view');
  };

  const DownloadBtn = (attachment: any) => (
    <Button
      className="downloadBtn note-btn"
      onClick={() => handleDownloadClick(attachment)}
      id="downloadAttachment"
    >
      <FileDownload
        className="material-icons-outlined"
        style={{
          fontSize: '1.125rem',
          width: '20px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
      Download
    </Button>
  );

  const ViewBtn = (Fid: number) => (
    <Button
      className="viewBtn note-btn"
      onClick={() => handleViewClick(Fid)}
      id="viewAttachment"
      sx={{ marginRight: '8px' }}
    >
      <PageviewOutlined
        className="material-icons-outlined"
        style={{
          fontSize: '1.125rem',
          width: '20px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
      View
    </Button>
  );

  const buildAttachments = (attachmentTypeId: number) => {
    const filteredAttachments = attachmentsById?.data && attachmentsById?.data.filter((attachment: any) => attachment.AttachmentTypeId === attachmentTypeId)

    return (
      <Grid container direction="column" spacing={1} sx={{ padding: "8px 16px", borderBottom: "1px solid #ddd" }}>
        {filteredAttachments?.map((attachment: any) => (
          <Grid item xs={12} key={attachment.Fid.toString()} sx={{ padding: "8px 0" }}>
            <Typography variant="body2" color="textSecondary">
              {dayjs(attachment.UpdatedAt).format("MM/DD/YYYY")} By {attachment.UpdatedByName} {dayjs(attachment.UpdatedAt).format("hh:mm a")}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {attachment.Filename}
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "4px" }}>
              <Grid item>{ViewBtn(attachment.Fid)}</Grid>
              <Grid item>{DownloadBtn(attachment)}</Grid>
            </Grid>
            <Box sx={{ borderBottom: "1px solid #ddd", marginTop: "8px" }} />
          </Grid>
        ))}
      </Grid>
    );
  };

  const buildAttachmentTypes = () => {
    const sortedAttachments = attachmentTypesByCustomer?.data.sort((a: any, b: any) => {
      if (a.CatchAll && !b.CatchAll) return 1;
      return a.Name.localeCompare(b.Name);
    });

    return (
      <Grid container direction="column" spacing={3} sx={{ padding: "16px", borderRadius: "8px" }}>
        {sortedAttachments.map((attachmentType: any) => (
          <Grid item xs={12} key={attachmentType.ATID.toString()} sx={{ marginBottom: "16px" }}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              {attachmentType.Name}
            </Typography>
            <Box sx={{ marginBottom: "8px" }}>
              <ChooseFileBtn attachmentType={attachmentType} />
            </Box>
            {buildAttachments(attachmentType.ATID)}
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      {attachmentTypesByCustomer.data && buildAttachmentTypes()}
      <Dialog open={isDialogOpen && selectedFile[0] !== null} fullWidth onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Select Attachment Type</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="type"
            sx={{
              mt: 2
            }}
            options={
              attachmentTypesByCustomer?.isPending
                ? []
                : attachmentTypesByCustomer?.data
                  ?.filter((data: any) => data.CatchAll) // Filter items with CatchAll true
                  .map((data: any) => ({ // Map to the format needed for options
                    id: data.ATID,
                    label: data.Name,
                  })) || []
            }
            // options={ [] }
            renderInput={(params) => <TextField {...params} label="Attachment Type" />}
            loading={attachmentTypesByCustomer?.isPending}
            // autocompleteProps={{
            //   id: 'type',
            //   loadingText: 'Loading Types...',
            //   size: 'small'
            // }}
            multiple={false}
            size='small'
            fullWidth
          // label="Type"
          // required
          />
          <DialogContentText mt={4}>
            {/* {selectedFile?.FileList?.map((file: any) => (file.name)) || 'No file selected'} */}
            {selectedFile?.map((file: any) => (file.name))}
          </DialogContentText>
          {/* You can add more dialog content here, like buttons for selecting file types */}
        </DialogContent>
        <DialogActions>
          <Button id='cancelType' onClick={handleDialogCancel}>Cancel</Button>
          <Button id='AttachType' onClick={handleDialogClose} autoFocus>Attach</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
