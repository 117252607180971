import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import DrawerHeader from '@/components/Organisms/BasicDrawer/DrawerHeader.component';
import { DrawerLayout } from '@/layouts/DrawerLayout';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Container, Step, StepLabel, Stepper, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Box, Divider, Stack, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface CustomStepIconProps {
    icon: React.ReactNode;
    active: boolean;
    completed: boolean;
}

const BasicDrawer = () => {
    const navigate = useNavigate();
    const params = useParams({ from: '/status-editor/$statusId' });
    const { variant, step } = useSearch({ from: '/status-editor/$statusId' })

    const step1Ref = useRef<any>(null);
    const step2Ref = useRef<any>(null);

    const theme = useTheme();

    const statusId = params.statusId

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});

    const [isOpen, setIsOpen] = useState(false)

    // Open drawer when component mounts
    useEffect(() => {
        setIsOpen(true)
    }, [])

    const handleClose = () => {
        setIsOpen(false)
        // Wait for animation to complete before navigating
        setTimeout(() => {
            navigate({ to: '/status-editor' })
        }, 300) // Match this with MUI's transition duration
    }

    const handleCloseDrawer = () => {
        handleClose()
        setActiveStep(0);
        setCompleted({});
        // methods.reset(editCustomerValues2);
        navigate({ to: '/status-editor' });
    };

    const handleSubmit: SubmitHandler<any> = (formValues: any) => {
        console.log('formValues : ', formValues);

        if (variant === 'edit') {
        } else {
        }
    }

    const steps = [
        {
            label: 'Step 1',
            children: <div>Hello Step 1</div>,
            completed: false,
        },
        {
            label: 'Step 2',
            children: <div>Hello Step 2</div>,
            completed: false,
        },
    ]

    const totalSteps = () => {
        return steps.length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
        console.log(newActiveStep);
        setActiveStep(newActiveStep);
        if (activeStep === 1) {
            step1Ref.current?.submitForm();
            setCompleted({ ...completed, [activeStep]: true });
        }
        if (activeStep === 2) {
            step2Ref.current?.submitForm();
        }
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
        navigate({
            to: "/status-editor/$statusId",
            params: { statusId },
            search: (prev) => ({
                ...prev,
                variant: variant,
                step: step,
            })
        })
        if (activeStep === 1) {
            step1Ref.current?.submitForm();
        }
        if (activeStep === 2) {
            step2Ref.current?.submitForm();
        }
    };

    const handleComplete = () => {
        if (completedSteps() === totalSteps() - 1) {
            console.log('saved');
        } else {
            const newCompleted = completed;
            newCompleted[activeStep] = true;
            setCompleted(newCompleted);
            handleNext();
        }
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        navigate({ to: '/status-editor' });
    };

    const CustomStepIcon: React.FC<CustomStepIconProps> = ({ icon, active, completed }) => {
        return (
            <>
                {variant !== 'edit' ? (
                    completed ? (
                        <Box
                            id="completed button"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'green',
                                width: 26,
                                height: 26,
                                borderRadius: '50%',
                                // color: '#fff',
                            }}
                        >
                            <CheckCircleIcon sx={{ color: 'green' }} />
                        </Box>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 24,
                                height: 24,
                                borderRadius: '50%',
                                backgroundColor: active ? theme.palette.primary.light : completed ? '#4caf50' : '#e0e0e0',
                                color: '#fff',
                            }}
                        >
                            <Typography>{completed ? <CheckCircleIcon sx={{ color: 'white' }} /> : icon}</Typography>
                        </div>
                    )
                ) : undefined}
            </>
        );
    };

    const StepperNumberJSX = () => (
        <Box sx={{ pe: 8, display: 'flex', justifyContent: 'space-evenly' }}>
            <Stepper nonLinear activeStep={activeStep} connector={null} alternativeLabel sx={{ pe: 4, width: '50%', mb: 4 }}>
                {steps.map((step, index) => (
                    <Step key={step.label} completed={completed[index]} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <StepLabel StepIconComponent={CustomStepIcon} color="inherit" onClick={handleStep(index)}>
                            {step.label}
                            {activeStep === index ? (
                                <>
                                    <Divider
                                        sx={{
                                            width: '100%',
                                            height: '2px',
                                            mt: 2,
                                            // mb: 1,
                                            mx: 1,
                                            bgcolor: 'primary.main',
                                            color: 'primary.contrastText',
                                        }}
                                    />
                                </>
                            ) : null}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );

    const StepContent = steps[activeStep]?.children || 'Unknown Step';

    return (
        <DrawerLayout
            open={isOpen}
            onClose={handleCloseDrawer}
            anchor="right"
            keepMounted
            width={'50%'}
            Header={
                <DrawerHeader
                    close={handleCloseDrawer}
                    title={variant === 'edit' ? 'Edit Customer' : 'New Customer'}
                />
            }
        >
            <Grid
                container
                style={{
                    height: '100%',
                    width: '100%',
                }}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent={'space-between'}
                flexWrap="nowrap"
                padding={8}
            >
                <Container component='form'
                    sx={{ width: '100%' }}
                >
                    <Stack direction="column" sx={{ width: '100%' }}>
                        <Box sx={{ width: '100%' }}>
                            <StepperNumberJSX />
                            <Grid container direction="row" spacing={8}>
                                <Container>
                                    {StepContent}
                                </Container>
                            </Grid>
                            {/* <CustomerDrawerFooter
                                isEdit={variant === 'edit'}
                                activeStep={activeStep}
                                lastStep={1}
                                saveCallback={() => handleComplete()}
                                cancelCallback={handleReset}
                                entityType={'Customer'}
                                deleteCallback={handleDeleteCustomer}
                                isNextDisabled={false}
                            /> */}
                        </Box>
                    </Stack>
                </Container>
            </Grid>
        </DrawerLayout>
    );
};

export default BasicDrawer;