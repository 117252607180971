import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import { IconButton, Tooltip } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import React from 'react';
import { MRT_SortingState } from 'material-react-table';

// import { registerTableColumns } from '../components/Organisms/RegisterTable/registerTableColumns.constant'
// import { ticketListTableColumns } from '../../components/Organisms/TicketListTable/ticketListTableColumns'
import { ticketListTableColumns } from '../../components/Organisms/TicketListTable/ticketListTableColumns';
// @ts-ignore
import useColumns from '../useColumns';

import useTableInstance from './useTableInstance';
import { useNavigate } from '@tanstack/react-router';
import useTicketList from '@/contexts/stores/ticketList.store';

interface TicketListInstanceProps {
  data: any;
  loading: any;
  refreshQueryKey?: any[];
  setEditRow?: any;
  setEditRowValues?: any;
  setCustomerDrawerOpen?: any;
  sorting?: any;
  setSorting?: any;
}

const useTicketListTable = (props: TicketListInstanceProps) => {
  const { setEditRow, setEditRowValues, setCustomerDrawerOpen, refreshQueryKey, sorting, loading, setSorting, data } =
    props;
  const columns = useColumns(ticketListTableColumns);
  const navigate = useNavigate();
  const { updateTicketList, setSelectedTicketId } = useTicketList();

  const ticketListTable = useTableInstance({
    data: data,
    columns: columns,
    loading: loading,
    refreshQueryKey: refreshQueryKey,
    getRowId: (row: any) => row?.name,
    // isMultiSortEvent: () => true,
    // maxMultiSortColCount: 2,
    // // showColumnFilters: true,
    // enableMultiSort: true,
    // enableSorting: true,
    // renderRowActions: ({ row, table }: any) => (
    //     <Box
    //         sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
    //         onClick={(event: any) => {
    //             event.stopPropagation()
    //         }}
    //     >
    //         <Tooltip title="Edit">
    //             <IconButton
    //                 id="edit"
    //                 size="small"
    //                 onClick={(event: any) => {
    //                     event.stopPropagation()
    //                     setEditRow(true)
    //                     table.setEditingRow(row)
    //                     setEditRowValues(row.original)
    //                     setCustomerDrawerOpen({open: true, type: 'edit'})
    //                 }}
    //             >
    //                 <EditIcon />
    //             </IconButton>
    //         </Tooltip>
    //     </Box>
    // ),
    // muiTableBodyRowProps: ({ row }: any) => ({
    //     onClick: (event: any) => {
    //         event.stopPropagation()
    //         setEditRow(true)
    //         ticketListTable.setEditingRow(row)
    //         setEditRowValues(row.original)
    //         setCustomerDrawerOpen({open: true, type: 'edit'})
    //     },
    //     sx: {
    //         cursor: 'pointer', //you might want to change the cursor too when adding an onClick
    //     },
    // }),
    // onSortingChange: (updaterOrValue: any) => {
    //   const updateSortingState = (sortingState: MRT_SortingState) => {
    //     // Ensure the 'TId' sorting is always first and descending
    //     const companySorting = { id: 'TId', desc: true }

    //     // Find if the 'TId' column is already in the sorting state
    //     const companySortIndex = sortingState.findIndex((sort: any) => sort.id === 'TId')

    //     // Remove 'TId' if it's not in the first position or not descending
    //     if (companySortIndex > 0 || (companySortIndex === 0 && !sortingState[0].desc)) {
    //       sortingState = sortingState.filter((sort: any) => sort.id !== 'TId')
    //     }

    //     // If there are other sorts, keep only the most recent one
    //     const otherSorts = sortingState.filter((sort: any) => sort.id !== 'TId')
    //     if (otherSorts.length > 0) {
    //       sortingState = [otherSorts[otherSorts.length - 1]]
    //     }

    //     // Prepend the 'TId' sorting to maintain it as the primary sort
    //     return [companySorting, ...sortingState]
    //   }

    //   // Check if updaterOrValue is a function or a sorting state array
    //   if (typeof updaterOrValue === 'function') {
    //     // If it's a function, use it to update the sorting state
    //     setSorting((prevSortingState: MRT_SortingState) => updateSortingState(updaterOrValue(prevSortingState)))
    //   } else {
    //     // If it's a sorting state array, update it directly
    //     setSorting(updateSortingState(updaterOrValue))
    //   }
    // },
    onSortingChange: setSorting,
    enableColumnActions: true,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      sorting: [
        {
          id: 'TId',
          desc: true,
        },
      ],
    },
    state: { showSkeletons: loading, sorting: sorting },
    muiTableBodyRowProps: ({ row }: any) => ({
      onClick: () => {
        const id = row.original.TicketExt.Ticket.TId;
        navigate({ to: `/ticket/${id}` });
        // updateTicketList({ selectedTicket: { id:id }});
        setSelectedTicketId(id);
      },
      sx: {
        cursor: 'pointer',
      },
    }),
  });

  return { ticketListTable };
};

export default useTicketListTable;
