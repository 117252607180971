import { BASE_API_URL, BASE_UAPI_URL } from '@/constants/commonStrings.constant';
import { apiService, setupInterceptors } from '@/services/api.service';
import TicketService from '@/services/ticket.service';
import React, { useCallback, useContext, useEffect, useState } from 'react'

export interface AuthContext {
    isAuthenticated: boolean
    login: () => Promise<void>
    logout: () => Promise<void>
    getCurrentPath: () => string
    setCurrentPath: (path: string) => void
    checkAuthStatus: () => boolean;
    // initializeAuth: () => Promise<void>;
}

const AuthContext = React.createContext<AuthContext | null>(null)

interface AuthData {
    jwt: string;
    publicKey: string;
}

const key = 'tanstack.auth.user'

export interface LoginCredentials {
    username: string;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        return !!localStorage.getItem('jwt');
    });

    const ticketService = TicketService()

    const checkAuthStatus = useCallback(() => {
        const hasToken = !!localStorage.getItem('jwt');
        setIsAuthenticated(hasToken);
        return hasToken;
    }, []);

    // Initialize auth state and interceptors
    useEffect(() => {
        checkAuthStatus();
        setupInterceptors();
    }, [checkAuthStatus]);

    // Listen to localStorage changes from other tabs/windows
    // useEffect(() => {
    //     const handleStorageChange = (event: StorageEvent) => {
    //         if (event.key === 'jwt') {
    //             checkAuthStatus();
    //         }
    //     };

    //     window.addEventListener('storage', handleStorageChange);
    //     return () => window.removeEventListener('storage', handleStorageChange);
    // }, []);

    // Periodic check for JWT
    // useEffect(() => {
    //     const interval = setInterval(checkAuthStatus, 60000); // Check every minute
    //     return () => clearInterval(interval);
    // }, []);

    const logout = useCallback(async () => {
        // await sleep(250)
        await ticketService.killSession({})
        localStorage.removeItem('jwt');
        localStorage.removeItem('public_key');
        await setupInterceptors();
        setIsAuthenticated(false);
    }, [ticketService])

    const login = useCallback(async () => {
        // await sleep(500)
        await setupInterceptors();
        setIsAuthenticated(true);
    }, [])

    const setCurrentPath = useCallback((path: string) => {
        localStorage.removeItem('currentPath');
        localStorage.setItem('currentPath', path);
    }, [])

    const getCurrentPath = useCallback(() => {
        return localStorage.getItem('currentPath') ?? '/';
    }, [])

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, getCurrentPath, setCurrentPath, checkAuthStatus, }}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
