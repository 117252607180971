import { useQueryClient } from '@tanstack/react-query';
import { IconButton, Typography } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import type { MRT_RowData, MRT_TableOptions } from 'material-react-table';
import { MRT_ShowHideColumnsButton, useMaterialReactTable } from 'material-react-table';
import RefreshIcon from '@mui/icons-material/Refresh';
import { enqueueSnackbar } from 'notistack';
import { useTheme } from '@mui/material';

interface IProps extends MRT_TableOptions<MRT_RowData> {
  columns: any;
  data: any;
  loading: boolean;
  refreshQueryKey?: any[];
}

const customFilterFn = (row: any, columnId: any, filterValue: any) => {
  console.log(filterValue);
  const cellValue = row.getValue(columnId);
  console.log(cellValue);
  // Text filter
  if (typeof filterValue === 'string') {
    console.log(filterValue);
    const lowercaseFilterValue = filterValue.toLowerCase();
    return cellValue.toLowerCase().includes(lowercaseFilterValue);
  }

  // Date range filter
  if (Array.isArray(filterValue) && filterValue.length === 2 && filterValue[0] && filterValue[1]) {
    if (!filterValue[0] || !filterValue[1]) {
      return true; // If no filter value is selected, include all rows
    }
    const [start, end] = filterValue;
    const cellDate = new Date(cellValue * 1000);

    // Set time to start of day for cell date
    const cellDateStart = new Date(cellDate.setHours(0, 0, 0, 0));

    // Set time to start of day for start date
    const startDate = new Date(new Date(start).setHours(0, 0, 0, 0));

    // Set time to end of day for end date
    const endDate = new Date(new Date(end).setHours(23, 59, 59, 999));

    // If start and end are the same, only include exact matches
    if (startDate.getTime() === endDate.getTime()) {
      return cellDateStart.getTime() === startDate.getTime();
    }

    // Check if the cellDate is within the range (inclusive)
    return cellDateStart >= startDate && cellDateStart <= endDate;
  }

  return true; // If no filter is applied
};

const useTableInstance = (props: IProps) => {
  const { refreshQueryKey } = props;
  // console.warn(refreshQueryKey)
  const theme = useTheme();

  const queryClient = useQueryClient();

  const Message = (message: string) => {
    return <Typography>{message}</Typography>;
  };

  const handleRefresh = async () => {
    if (!refreshQueryKey) return;
    // console.log('refreshing')
    const previousData = queryClient.getQueryData(refreshQueryKey);
    // console.log(previousData)

    await queryClient.invalidateQueries({ queryKey: refreshQueryKey, exact: true }); // Replace 'yourQueryKey' with the actual query key
    const newData = queryClient.getQueryData(refreshQueryKey);
    // console.log(newData)
    if (newData) {
      if (JSON.stringify(newData) !== JSON.stringify(previousData)) {
        enqueueSnackbar(Message('New records found!'), { variant: 'success' });
      } else {
        enqueueSnackbar(Message('Table successfully updated'), {
          variant: 'default',
          style: { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText },
        });
      }
      // } else {
      // enqueueSnackbar(Message('No new records found'), { variant: 'error' })
    }
  };

  const table = useMaterialReactTable({
    enableToolbarInternalActions: true,
    enablePagination: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: true,
    positionGlobalFilter: 'left',
    enableGlobalFilterModes: true,
    enableGlobalFilterRankedResults: true,
    enableFilterMatchHighlighting: false,
    enableColumnFilters: false,
    enableColumnFilterModes: false,
    muiColumnActionsButtonProps: {
      size: 'small',
      color: 'primary',
    },
    positionToolbarAlertBanner: 'head-overlay',
    enableEditing: true,
    enableColumnActions: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
        size: 10,
      },
    },
    createDisplayMode: 'custom',
    editDisplayMode: 'custom',
    muiTablePaperProps: {
      sx: {
        p: 2,
        borderRadius: '6px',
        boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px',
        backgroundImage: 'none',
      },
    },
    muiTableBodyProps: {
      sx: {
        border: 'none',
      },
    },
    renderToolbarInternalActions: ({ table }: any) => (
      <>
        {/* add your own custom print button or something */}
        <IconButton onClick={handleRefresh} id={'refresh'}>
          <RefreshIcon />
        </IconButton>
        {/* built-in buttons (must pass in table prop for them to work!) */}
        <MRT_ShowHideColumnsButton table={table} />
      </>
    ),
    filterFns: {
      customFilterFn,
    },
    ...props,
  });
  return table;
};

export default useTableInstance;
