import React, { useEffect, useMemo, useState } from 'react';
import DashboardLayout from '@/layouts/DashboardLayout';
import SimpleTable from '@/components/Molecules/Table/SimpleTable.component';
import useTicketListTable from '@/hooks/tables/useTicketListTable';
import useTicket from '@/hooks/fetches/useTicket.service';
import SingleDropdown from '@/components/Molecules/Dropdowns/Singledropdown.component';
import { Box, Card, Grid, Typography } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
// import AutoComplete from '@/components/Molecules/AutoComplete/AutoComplete.component'
import { AutoComplete, Button } from '@utilisourcepackagelibdev/utilisourcepackagelib';
// import Button from '@/components/Atoms/Button/Button.component'
import useTicketList from '@/contexts/stores/ticketList.store';
import {
  TicketListFilterSchema,
  ticketListFilterSchema,
  defaultValues,
} from '@/interfaces/schemas/ticketListFilter.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import TicketListMap from '@/components/Organisms/TicketListMap.component';
import { setupInterceptors } from '@/services/api.service';
import { useAuth } from '@/lib/auth';
import { redirect, useNavigate } from '@tanstack/react-router';
import { env } from '@/config/env';

const TicketList = () => {
  // const [editRow, setEditRow] = useState<any>(false);
  // const [editRowValues, setEditRowValues] = useState<any>(null);
  // const [customerDrawerOpen, setCustomerDrawerOpen] = useState({ open: false, type: '' });
  const [sorting, setSorting] = useState<any>([{ id: 'TId', desc: true }]);
  // const [selectedCustomer, setSelectedCustomer] = useState<number | undefined>(undefined);
  // const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
  // const [assigneeFilterApplied, setAssigneeFilterApplied] = useState<boolean>(false); // To track the toggle state

  const { ticketListState, updateTicketList } = useTicketList();

  // console.log('ticketListState:', ticketListState);

  const methods = useForm<TicketListFilterSchema>({
    mode: 'all',
    resolver: zodResolver(ticketListFilterSchema),
    defaultValues,
  });

  const customerValue = useWatch({ control: methods.control, name: 'customer_filter' });
  const categoryValue = useWatch({ control: methods.control, name: 'category_filter' });

  // const customerId = 10;
  // const category = 'closed';

  const { allTickets, customers } = useTicket(
    undefined,
    ticketListState?.selectedCustomer?.id,
    ticketListState?.selectedCategory?.label,
  );

  const handleSubmit = (formValues: any) => {
    ticketListTable.resetColumnFilters();
    updateTicketList({ selectedCustomer: customerValue, selectedCategory: categoryValue });
    // ticketListTable.setCreatingRow(formValues)
    const customer = ticketListTable.setColumnFilters((prev) => [
      ...prev,
      {
        id: 'CustomerName',
        value: formValues.customer_filter ? formValues.customer_filter?.label : '',
      },
    ]);

    return { customer };
  };

  // const handleCustomerChange = (customer: any) => {
  //   const customerId = customer ? customer.id : undefined;
  //   // setSelectedCustomer(customerId);
  //   updateTicketList({ selectedCustomer: customerId })
  // };

  // const handleCategoryChange = (category: any) => {
  //   const categoryValue = category ? category.label : undefined;
  //   // setSelectedCategory(categoryValue);
  //   updateTicketList({ selectedCategory: categoryValue })
  // };

  const handleToggleTicketListMap = () => {
    updateTicketList({ ticketListMapOpen: true });
  };

  const handleToggleAssigneeFilter = () => {
    const currentFilterApplied = ticketListState.assigneeFilterApplied;

    updateTicketList({ assigneeFilterApplied: !currentFilterApplied });

    if (currentFilterApplied) {
      // Remove the filter
      ticketListTable.setColumnFilters((prev) => prev.filter((filter) => filter.id !== 'Assignee'));
    } else {
      // Apply the filter
      ticketListTable.setColumnFilters((prev) => [...prev, { id: 'Assignee', value: ['Arna Wade'] }]);
    }
  };

  const { ticketListTable } = useTicketListTable({
    // setEditRow,
    // setEditRowValues,
    // setCustomerDrawerOpen,
    sorting,
    setSorting,
    loading: allTickets.isLoading,
    data: allTickets.data ? allTickets.data : [],
    refreshQueryKey: ['all-tickets', ticketListState?.selectedCustomer?.id, ticketListState?.selectedCategory?.label],
  });

  // useEffect(() => {
  //   updateTicketList({ selectedCustomer: customerValue, selectedCategory: categoryValue })
  //   // updateTicketList({ selectedCategory: categoryValue })
  // }, [customerValue, categoryValue])

  useEffect(() => {
    // When the component mounts, check if the assignee filter should be applied
    if (ticketListState.assigneeFilterApplied) {
      ticketListTable.setColumnFilters((prev) => [
        ...prev,
        { id: 'Assignee', value: ['Arna Wade'] }, //! Replace this with a dynamic agent name
      ]);
    } else {
      ticketListTable.setColumnFilters((prev) => prev.filter((filter) => filter.id !== 'Assignee'));
    }
  }, [ticketListState.assigneeFilterApplied, ticketListTable]);

  useEffect(() => {
    methods.setValue('customer_filter', ticketListState.selectedCustomer);
    methods.setValue('category_filter', ticketListState.selectedCategory);
    updateTicketList({ selectedTicket: { id: 0 } });
  }, []);

  const navigate = useNavigate()

  const handleLogout = () => {
    console.log('logout')
    logout()
  }

  const handleAdmin = () => {
    window.location.href = 'http://localhost:8000/www/custom-fields-admin.html'
    // window.location.href = env.TARGET_APP_URL + env.
  }

  const { logout } = useAuth();

  return (
    <>
      <Grid container display="flex" flexDirection="row" flexWrap="wrap" rowGap={4} mt={8} sx={{ height: '100%' }}>
        <Grid item xs={12} md={3} display="flex" flexDirection="column" rowGap={4} paddingX={2} paddingY={1}>
          <Typography variant="h3">Filters</Typography>
          <Card sx={{ p: 4 }}>
            <FormProvider {...methods}>
              <form className="d-flex flex-column row-gap-4" onSubmit={methods.handleSubmit(handleSubmit)}>
                <Grid item xs={12} display="flex" flexDirection="column" rowGap={4} paddingX={2} paddingY={1}>
                  <AutoComplete
                    id="customer_filter"
                    options={
                      customers.isPending
                        ? []
                        : customers?.data?.map((customer: any) => ({
                          id: customer.CustomerEntId,
                          label: customer.EntName,
                        })) || []
                    }
                    label="Customer"
                  // onChange={(e, customer) => handleCustomerChange(customer)}
                  // value={customers?.data?.data?.find((customer: any) => customer.CustomerEntId === ticketListState.selectedCustomer) || null}
                  // isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                  <AutoComplete
                    id="category_filter"
                    options={[
                      {
                        id: 0,
                        label: 'Active',
                      },
                      {
                        id: 1,
                        label: 'Closed',
                      },
                      {
                        id: 2,
                        label: 'All',
                      },
                      {
                        id: 3,
                        label: 'Assigned (Gateway)',
                      },
                    ]}
                    label="Category"
                  // onChange={(e, category) => handleCategoryChange(category)}
                  />
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      id="clear"
                      variant="outlined"
                      sx={{ marginRight: 2 }}
                      onClick={() => {
                        methods.reset();
                        ticketListTable.resetColumnFilters();
                      }}
                    >
                      Clear
                    </Button>
                    <Button id="filterResults" variant="contained" type="submit">
                      Filter Results
                    </Button>
                  </div>
                </Grid>
              </form>
            </FormProvider>
          </Card>
        </Grid>
        <Grid item xs={12} md={8} paddingX={2} paddingY={1} height={'100%'}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              id="ticketListMap"
              variant="contained"
              onClick={handleToggleTicketListMap}
              sx={{ marginRight: 5 }}
              disabled={!ticketListTable.getRowModel().rows.length}
            >
              Map
            </Button>
            <Button id="assignedToMe" variant="contained" onClick={handleToggleAssigneeFilter}>
              Assigned to Me
            </Button>
            <Button id='logout' variant="contained" onClick={handleLogout} sx={{ ml: 4 }}>Logout</Button>
            <Button id='goto' variant="contained" onClick={handleAdmin} sx={{ ml: 4 }}>Admin</Button>
          </Box>
          <SimpleTable table={ticketListTable} showDevTools />
        </Grid>
        {ticketListState.ticketListMapOpen && <TicketListMap table={ticketListTable} />}
      </Grid>
    </>
  );
};

export default TicketList;
