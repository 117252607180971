import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import GlobalStateProvider from './contexts/globalStateProvider';

function render() {
  createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <GlobalStateProvider>
        <App />
      </GlobalStateProvider>
    </StrictMode>,
  );
}
render();
