import TextField from '@/components/Atoms/TextField/TextField.component';
import SingleDropdown from '@/components/Molecules/Dropdowns/Singledropdown.component';
import { TicketData, TicketExt } from '@/interfaces/dropsource.interfaces';
import dayjs from 'dayjs';

export const ticketListTableColumns = [
  {
    header: 'ID',
    //accessorKey required for ColumnOrdering
    accessorKey: 'TId',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.TId;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.TId;
      } else {
        return null;
      }
    },
    // muiTableHeadCellProps: { style: { color: 'red' } }, //custom props
    // muiTableBodyCellProps: {
    //     align: 'left',
    //     style: { color: 'blue', backgroundColor: 'yellow' },
    // },
    size: 105,
    enableResizing: false,
    enableEditing: false,
  },
  {
    header: 'Status',
    accessorKey: 'Status',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Status.Name;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Status.Name;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Status</i>, //optional custom markup
    size: 150,
    // editVariant: 'select',
    // Edit(props: any) {
    //     // console.log(props.row)
    //     // console.log(ticketState.baseFields.status)
    //     // console.log(props.row.original.TicketExt.Status.StatusId)
    //     // console.log(props.row.original.TicketExt.Status.Name)
    //     return (
    //         <SingleDropdown
    //             id={props.row.original.TicketExt.Status.StatusId}
    //             name={props.row.original.TicketExt.Status.Name}
    //             options={ticketState.baseFields.status}
    //             value={props.row.original.TicketExt.Status.Name}
    //             label={"Status"}
    //             onDataChange={changer}
    //         />
    //     )
    // },
  },
  {
    header: 'Type',
    accessorKey: 'Type',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Type) {
        return row.TicketExt.Type.Name;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Type.Name;
      } else {
        return null;
      }
    },
    size: 105,
    enableResizing: false,
    enableEditing: false,
  },
  {
    header: 'Priority',
    accessorKey: 'Priority',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.Priority;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.Priority;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Priority</i>, //optional custom markup
    size: 125,

    // Edit(props: any) {
    //     // console.log(props.row)
    //     // console.log(ticketState.baseFields)
    //     return (
    //         <SingleDropdown
    //             id={props.row.original.TicketExt.Ticket.Priority}
    //             name={props.row.original.TicketExt.Ticket.Priority}
    //             options={ticketState.baseFields.priority}
    //             value={props.row.original.TicketExt.Ticket.Priority}
    //             label={"Priority"}
    //             onDataChange={changer}
    //         />
    //     )
    // },
  },
  {
    header: 'Title',
    accessorKey: 'Title',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.Title;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.Title;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Title</i>, //optional custom markup
    // maxSize: 500,
    size: 250,
    muiEditTextFieldProps: {
      type: 'text',
      required: true,

      //remove any previous validation errors when user focuses on the input
    },
    Edit(props: any) {
      // console.log(props.row)
      return (
        <TextField
          id={props.row.original.TicketExt.Ticket.Tid}
          value={props.row.original.TicketExt.Ticket.Title}
          placeholder={props.row.original.TicketExt.Ticket.Title}
          // onChange={changer}
          label={'Title'}
          required
          // fullWidth
        />
      );
    },
  },
  {
    header: 'Assignee',
    accessorKey: 'Assignee',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Assignee.Name;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Assignee.Name;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Assignee</i>, //optional custom markup
    enableEditing: false,
  },
  {
    header: 'Customer',
    accessorKey: 'CustomerName',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.CustomerEntity.EntName;
      } else if ('Ticket' in row && row.Ticket) {
        return row.CustomerEntity.EntName;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Customer</i>, //optional custom markup
    // editVariant: 'select',
    // muiEditTextFieldProps: {
    //     select: true,
    //     required: true,
    //     variant: 'outlined',

    //     //remove any previous validation errors when user focuses on the input
    // },
    // Edit(props: any) {
    //     console.log(props.row)
    //     console.log(ticketState)
    //     console.log(props.row.original.TicketExt.CustomerEntity.CustomerEntId)
    //     return (
    //         <SingleDropdown
    //             id="customer-select"
    //             label="Customer"
    //             placeholder="Select Customer"
    //             value={props.row.original.TicketExt.CustomerEntity.CustomerEntId.toString()}
    //             options={ticketState.homeTableCustomer.options}
    //             onDataChange={changer}
    //         />
    //     )
    // },
  },
  {
    header: 'Raised By',
    accessorKey: 'RaisedBy',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Raised.Name;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Raised.Name;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Raised By</i>, //optional custom markup
    enableEditing: false,
  },
  {
    header: 'Due Date',
    accessorKey: 'DueDate',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        const date = row.TicketExt.Ticket.DueDate;
        return date ? dayjs(date).format('M/DD/YY hh:mm a') : '';
      } else if ('Ticket' in row && row.Ticket) {
        const date = row.Ticket.DueDate;
        return date ? dayjs(date).format('M/DD/YY hh:mm a') : '';
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Due Date</i>,
    enableEditing: false,
  },
  {
    header: 'Created',
    accessorKey: 'Created',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        const date = row.TicketExt.Ticket.CreateTime;
        return date ? dayjs(date).format('M/DD/YY hh:mm a') : '';
      } else if ('Ticket' in row && row.Ticket) {
        const date = row.Ticket.CreateTime;
        return date ? dayjs(date).format('M/DD/YY hh:mm a') : '';
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Created</i>,
    enableEditing: false,
  },
  {
    header: 'Client Name',
    accessorKey: 'ClientName',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.ClientName;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.ClientName;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Client Name</i>, //optional custom markup
    enableEditing: false,
  },
  {
    header: 'Client Email',
    accessorKey: 'ClientEmail',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.ClientEmail;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.ClientEmail;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Client Email</i>, //optional custom markup
    enableEditing: false,
  },
  {
    header: 'Client Phone',
    accessorKey: 'ClientPhone',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.ClientPhone;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.ClientPhone;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Client Phone</i>, //optional custom markup
    enableEditing: false,
  },
  {
    header: 'Client Address',
    accessorKey: 'ClientAddress',
    accessorFn: (row: TicketData | TicketExt) => {
      if ('TicketExt' in row && row.TicketExt && row.TicketExt.Ticket) {
        return row.TicketExt.Ticket.ClientAddress;
      } else if ('Ticket' in row && row.Ticket) {
        return row.Ticket.ClientPhone;
      } else {
        return null;
      }
    },
    Header: <i style={{ color: '' }}>Client Address</i>, //optional custom markup
    enableEditing: false,
  },
];
