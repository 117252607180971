import { LoginCredentials, useAuth } from '@/lib/auth';
import { createFileRoute, useNavigate, useSearch } from '@tanstack/react-router'
import { Box, Button, Typography, useWindowSize } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader2 } from 'lucide-react';
import { Alert, Dialog, DialogProps, useTheme } from '@mui/material';
import { env } from '@/config/env';

// Types for PostMessage events
interface AuthDataMessage {
    type: 'AUTH_DATA'
    jwt: string
    publicKey: string
}

interface AuthCompleteMessage {
    type: 'AUTH_COMPLETE'
    success: boolean
    error?: string
}

interface ReadyForAuthMessage {
    type: 'READY_FOR_AUTH'
}

type PostMessageEvent = AuthDataMessage | AuthCompleteMessage | ReadyForAuthMessage

// Create a custom Dialog component that can be animated
const AnimatedDialog = forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
    return <Dialog {...props} ref={ref} />;
});

export const Route = createFileRoute('/login')({
    validateSearch: (search: Record<string, unknown>) => {
        return {
            redirect: search.redirect as string,
        }
    },
    component: () => <LoginPage />,
})

function LoginPage() {
    const [showDialog, setShowDialog] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();
    const { redirect } = Route.useSearch()
    const theme = useTheme()
    const height = useWindowSize().height
    const iframeRef = useRef<HTMLIFrameElement>(null)
    // const searchParams = useSearch({
    //     from: '/'
    // });
    const [isLoaded, setIsLoaded] = useState(false)
    const handleIframeLoad = () => {
        setIsLoaded(true)
    }

    const targetOrigin = env.TARGET_APP_URL + env.ROUTING_PREFIX
    const loginURL = `${targetOrigin}${env.TARGET_APP_LOGIN_URL}`

    // Helper function to send messages to the legacy app
    const sendMessage = (message: PostMessageEvent) => {
        if (!iframeRef.current?.contentWindow) return
        iframeRef.current.contentWindow.postMessage(message, env.TARGET_APP_URL)
    }

    // Handle incoming messages from the legacy app
    const handleMessage = (event: MessageEvent) => {
        console.log('Received message REACT:', event.data)
        console.log(event.origin + ' vs ' + env.TARGET_APP_URL)
        // Verify origin
        if (event.origin !== env.TARGET_APP_URL) return

        console.log('Received message:', event.data)

        switch (event.data.type) {
            case 'AUTH_DATA':
                handleAuthData(event.data)
                break
            case 'AUTH_COMPLETE':
                handleAuthComplete(event.data)
                break
            default:
                console.warn('Unknown message type:', event.data.type)
        }
    }

    // Handle AUTH_DATA message
    const handleAuthData = async (data: AuthDataMessage) => {
        console.log('Storing auth data:', data)
        try {
            localStorage.setItem('jwt', data.jwt)
            localStorage.setItem('public_key', data.publicKey)

            await auth.login()

            // Notify legacy app of successful storage
            sendMessage({
                type: 'AUTH_COMPLETE',
                success: true
            })

            // Update auth context and redirect
            navigate({
                to: redirect || '/',
                replace: true
            })
        } catch (error) {
            console.error('Failed to store auth data:', error)
            sendMessage({
                type: 'AUTH_COMPLETE',
                success: false,
                error: 'Failed to store authentication data'
            })
        }
    }

    // Handle AUTH_COMPLETE message
    const handleAuthComplete = (data: AuthCompleteMessage) => {
        if (data.success) {
            console.log('Authentication completed successfully')
            navigate({
                to: redirect || '/',
                replace: true
            })
        } else {
            console.error('Authentication failed:', data.error)
            // Handle authentication failure (show error message, etc.)
        }
    }

    // const DotsAnimation = () => {
    //     return (
    //         <Box component="span" sx={{ display: 'inline-flex', marginLeft: 1 }}>
    //             {[0, 1, 2].map((index) => (
    //                 <motion.span
    //                     key={index}
    //                     initial={{ opacity: 0 }}
    //                     animate={{ opacity: 1 }}
    //                     transition={{
    //                         duration: 0.5,
    //                         repeat: Infinity,
    //                         repeatType: "reverse",
    //                         delay: index * 0.2,
    //                     }}
    //                     style={{ marginLeft: 2 }}
    //                 >
    //                     .
    //                 </motion.span>
    //             ))}
    //         </Box>
    //     );
    // };

    // useEffect(() => {
    //     const iframe = iframeRef.current || (document.querySelector('iframe') as HTMLIFrameElement)

    //     const handleLoad = () => {
    //         // hideSplashScreen()
    //         if (!iframe) return
    //         const contentWindow = iframe.contentWindow
    //         if (!contentWindow) return
    //         contentWindow.postMessage(
    //             {
    //                 instance: newInstance,
    //                 // url: urlHistoryState.drop,
    //                 url: '',
    //             },
    //             targetOrigin,
    //         )
    //     }

    //     if (iframe) {
    //         iframe.addEventListener('load', handleLoad)
    //     }

    //     // Cleanup function to remove the event listener
    //     return () => {
    //         if (iframe) {
    //             iframe!.removeEventListener('load', handleLoad)
    //         }
    //     }
    // }, [targetOrigin])

    // useEffect(() => {
    //     /*************  ✨ Codeium Command ⭐  *************/
    //     /**
    //      * Listens for messages from the iframe and updates the urlHistory state.
    //      * @param {any} event - The message event.
    //      */
    //     /******  94317e07-5bd2-42df-8ee2-c1417d914e97  *******/
    //     const receiveMessage = (event: any) => {
    //         if (event.origin !== targetOrigin && event.origin !== 'http://localhost:8000') return
    //         console.log('Received message from iframe: ', event.data)
    //         // updateUrlHistory(UPDATE_URL, {
    //         //   ...urlHistoryState,
    //         //   drop: event.data.url,
    //         // })
    //         //   console.log(urlHistoryState)
    //     }

    //     window.addEventListener('message', receiveMessage)

    //     // Cleanup function to remove the event listener
    //     return () => {
    //         window.removeEventListener('message', receiveMessage)
    //     }
    // }, [])


    // Set up message listener
    useEffect(() => {
        window.addEventListener('message', handleMessage)
        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.default',
            }}
        >
            {/* {!isLoaded && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                    }}
                >
                    <Typography variant="h6">Loading authentication...</Typography>
                </Box>
            )} */}
            <iframe
                ref={iframeRef}
                src={loginURL}
                title="Drop"
                width="100%"
                frameBorder={0}
                sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation allow-popups allow-popups-to-escape-sandbox allow-presentation allow-modals allow-orientation-lock allow-pointer-lock allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-top-navigation"
                allowFullScreen
                allowTransparency
                // allowTransparency
                loading="lazy"
                height={height}
                // onLoad={handleIframeLoad}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                style={{
                    // visibility: isLoaded ? 'visible' : 'hidden',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                }}
            ></iframe>
            {/* <Box
                sx={{
                    bgcolor: 'white',
                    borderRadius: 4,
                    p: 6,
                    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                    textAlign: 'center',
                    maxWidth: 400,
                }}
            >
                <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    {showAlert ? null : (
                        <motion.div
                            animate={{ rotate: 360 }}
                            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                            style={{ display: 'inline-block', marginBottom: '1.5rem' }}
                        >
                            <Loader2 size={48} color={theme.palette.primary.main} />
                        </motion.div>
                    )}

                    <Typography
                        variant="h4"
                        component="h1"
                        sx={{
                            color: 'primary.main',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {showAlert ? 'You are already signed in' : (
                            <>
                                Redirecting
                                <DotsAnimation />
                            </>
                        )}
                    </Typography>
                    {showAlert && (
                        <Alert sx={{ mt: 3 }} severity="info">
                            You will be redirected back home in 3 seconds.
                        </Alert>
                    )}
                    {(showDialog && !showAlert) && (
                        <motion.div
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.1 }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    textAlign: 'center',
                                    color: 'primary.main',
                                    mt: 8
                                }}
                            >
                                Taking longer than expected?
                            </Typography>

                            <Typography
                                sx={{
                                    mb: 3,
                                    textAlign: 'center',
                                    color: 'text.secondary',
                                }}
                            >
                                Click below to try redirecting manually
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    id='redirect'
                                    variant="contained"
                                    onClick={handleLogin}
                                    sx={{
                                        borderRadius: '28px',
                                        px: 4,
                                        py: 1,
                                        textTransform: 'none',
                                        fontSize: '1rem',
                                    }}
                                >
                                    Redirect Now
                                </Button>
                            </Box>
                        </motion.div>
                    )}
                </motion.div>
                Hello world
            </Box> */}
        </Box>
    );
}
