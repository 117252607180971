import NotFound from '@/app/pages/NotFound/NotFound.page'
import TicketList from '@/app/pages/TicketList/TicketList.page'
import DashboardLayout from '@/layouts/DashboardLayout'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

export const Route = createFileRoute('/_auth/')({
  validateSearch: (search) => {
    return z
      .object({
        category: z
          .enum(['Active', 'Closed', 'All', 'Assigned (Gateway)'])
          .optional(),
        categoryId: z.number().min(0).max(5).optional(),
        customerId: z.number().min(0).max(20).optional(), // Based on your steps array length
        customerName: z.string().optional(),
      })
      .parse(search)
  },
  component: HomeLayout,
  notFoundComponent: NotFound,
})

function HomeLayout() {
  return (
    <DashboardLayout title="Ticket List">
      <TicketList />
    </DashboardLayout>
  )
}
