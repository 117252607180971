import { Grid2 as Grid, Card, Paper, CardContent, Typography, Box, Autocomplete, AutocompleteRenderInputParams, AccordionDetails, TextField } from '@mui/material'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import useTicketList from '@/contexts/stores/ticketList.store'
import useTicket from '@/hooks/fetches/useTicket.service'
import dayjs from 'dayjs';
import { Accordion, AccordionSummary, Button } from '@utilisourcepackagelibdev/utilisourcepackagelib';
import { timezoneOffset } from '@/util/timezoneOffset.util';
import utc from 'dayjs/plugin/utc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

export default function Attachments() {

  const { ticketListState, updateTicketList } = useTicketList();
  dayjs.extend(utc);
  const [showAddNoteButton, setShowAddNoteButton] = useState(true);
  const methods = useForm();

  const { formState, control } = methods;

  // console.log('ticketListState:', ticketListState);

  const {
    notesById,
    updateTicketNotes,
  } = useTicket(
    ticketListState?.selectedTicket?.id,
    ticketListState?.selectedCustomer?.id
  );

  // console.log('notesById.data:', notesById.data);

  const createNoteAccordions = () => {

    const sortedNotesWithSubjects = notesById?.data
      .filter((note: any) => note.Subject)
      .sort((a: any, b: any) => new Date(b.CreateTime).getTime() - new Date(a.CreateTime).getTime());

    // console.log('notesWithSubjects:', notesWithSubjects);

    // return notesWithSubjects?.map((note: any) => {
    //   return (
    //     <Accordion id={note.Nid.toString()} key={note.Nid} sx={{ marginBottom: 3 }}>
    //       <AccordionSummary
    //         sx={{
    //           backgroundColor: '#eeeeee',
    //           '&.Mui-expanded': { backgroundColor: '#e7edfb' },
    //           '& .MuiAccordionSummary-content': {
    //             display: "flex",
    //           },
    //         }}
    //       >
    //         <Grid>
    //           {note.AuthorAgentId} {dayjs(note.CreateTime).format("MM/DD/YYYY hh:mm a")}
    //         </Grid>
    //       </AccordionSummary>
    //       <AccordionDetails>
    //         {note.Subject}
    //       </AccordionDetails>
    //     </Accordion>
    //   );
    // });

    return sortedNotesWithSubjects?.map((note: any) => {
      // const offsetInMinutes = parseOffset(note.TimeZoneOffset);
      const offsetInMinutes = timezoneOffset(note.TimeZoneOffset);
      // console.log('offsetInMinutes:', offsetInMinutes);
      const localizedTime = dayjs.utc(note.CreateTime).utcOffset(offsetInMinutes);
      return (
        <Accordion id={note.Nid.toString()} key={note.Nid} sx={{ marginBottom: 2, borderRadius: 1, boxShadow: "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px 2px;", '&::before': { display: 'none' }, }}>
          <AccordionSummary
            sx={{
              // backgroundColor: '#eeeeee',
              // '&.Mui-expanded': { backgroundColor: '#e7edfb' },
              '& .MuiAccordionSummary-content': {
                display: "flex",
                flexDirection: "column",
              },
              padding: '8px 16px',
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="body2" >
              {note.AuthorAgentName} {dayjs(note.CreateTime).format("MM/DD/YYYY")} {localizedTime.format("hh:mm A")} {note.TimeZone}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              // backgroundColor: '#f0f4ff',
              padding: '16px',
              // borderTop: '1px solid #ddd',
            }}
          >
            <Typography variant="body1">
              {note.Subject}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )
    });

  };

  const onSubmit = async () => {
    const methodValues = methods.getValues()
    setShowAddNoteButton(true);

    console.log('methodValues:', methodValues);

    const preparedData = {
      "message": methodValues.note,
      "subject": "",
      "ticketid": ticketListState?.selectedTicket?.id,
    }

    console.log('preparedData:', preparedData);

    try {
      if (methodValues.note) {
        const notePost = await updateTicketNotes({ actionType: 'updateTicketNotes', data: preparedData });
        console.log('notePost:', notePost);
        if (typeof (notePost.data) === 'number') {
          console.log('Note was saved')
        }
      }
      // console.log('TicketDetail methods.reset')
      methods.reset({ note: "" });
    } catch (error) {
      console.error("Error updating Note:", error);
    }


    // methods.reset({ note: "" });
  }

  return (
    <>
      {showAddNoteButton && <Button id='addNoteBtn' variant='outlined' onClick={() => setShowAddNoteButton(false)} sx={{ mb: 4 }}>Add Note</Button>}
      {!showAddNoteButton &&
        <Box>
          <Button id='cancelNoteBtn' color='error' variant='outlined' onClick={() => setShowAddNoteButton(true)} sx={{ mb: 4 }}>Cancel</Button>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box component="form">
                <Controller
                  name="note"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-multiline-static"
                      label="Note"
                      multiline
                      rows={4}
                      sx={{ mb: 4, width: '30%' }}
                    />
                  )}
                />
              </Box>
            </form>
          </FormProvider>
          <Button id='saveNoteBtn' variant='outlined' onClick={methods.handleSubmit(onSubmit)} sx={{ mb: 4 }}>Save</Button>
        </Box>
      }
      {notesById.data && createNoteAccordions()}
    </>
  )
}
