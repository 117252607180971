import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, IconButton, CircularProgress } from '@mui/material';
import { MapContainer, TileLayer, LayersControl, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.css';
import L from 'leaflet';
import 'leaflet.locatecontrol';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import useTicket from '@/hooks/fetches/useTicket.service';
import useTicketList from '@/contexts/stores/ticketList.store';

interface MapMarkersProps {
  ticketId?: string;
  latitude?: number | null;
  longitude?: number | null;
}

interface LatLon {
  Latitude: number;
  Longitude: number;
}

interface LayerChangeHandlerProps {
  onLayerChange: (name: string) => void;
}

const TicketDetailMap = () => {
  const { ticketListState, updateTicketList } = useTicketList();
  const { ticketById } = useTicket(
    ticketListState?.selectedTicket?.id,
    ticketListState?.selectedCustomer?.id,
    ticketListState?.selectedCategory?.label,
  );
  const { data: ticketData } = ticketById || {};

  console.log('ticketData:', ticketData);

  // const [loading, setLoading] = useState(false);
  const [latLon, setLatLon] = useState<LatLon>();
  const mapRef = useRef<L.Map>(null);
  const { BaseLayer } = LayersControl;
  const savedLayer = localStorage.getItem('selectedLayer') || 'Google Hybrid';
  const [selectedLayer, setSelectedLayer] = useState(savedLayer);
  const locateControlRef = useRef<L.Control.Locate | null>(null);
  const [markerData, setMarkerData] = useState<any[]>([]);

  const MapMarkers = ({ ticketId, latitude, longitude }: MapMarkersProps): JSX.Element | null => {
    console.log('mapMarkers');
    console.log('ticketId', ticketId);
    console.log('latitude', latitude);
    console.log('longitude', longitude);
    console.log('ticketData.Ticket.DueDate:', ticketData.Ticket.DueDate);
    console.log('ticketData.Ticket.ClientAddress:', ticketData.Ticket.ClientAddress);
    console.log('ticketData.Status.Name:', ticketData.Status.Name);
    console.log('ticketData.Assignee.Name:', ticketData.Assignee.Name);
    console.log('ticketData.Status.Color:', ticketData.Status.Color);
    console.log('ticketData.Ticket.Priority:', ticketData.Ticket.Priority);

    const dateElement = document.getElementById('TicketDueDate2') as HTMLInputElement;
    const date = dateElement ? dateElement.value : '';

    const addressElement = document.querySelector('[data-field="client-address"] input') as HTMLInputElement;
    const address = addressElement ? addressElement.value : '';

    const statusSelect = document.querySelector('[data-field="status"] select.TicketStatus') as HTMLSelectElement;
    const selectedStatus = statusSelect ? statusSelect.options[statusSelect.selectedIndex].text : '';

    const assigneeElement = document.querySelector('widget[warg-name="TicketAssignee"] .tokenname');
    const assignee = assigneeElement ? assigneeElement.textContent : '';

    const markerColorElement = document.getElementById('TicketStatusDiv');
    const markerColor = markerColorElement ? markerColorElement.style.backgroundColor : '';

    console.log('markerColor:', markerColor);

    const priorityElement = document.querySelector('.TicketPriority') as HTMLInputElement;
    const criticalPriority = priorityElement && priorityElement.value === 'critical';

    const markerData1 = [
      {
        dueDate: dayjs(ticketData?.Ticket.DueDate).format('MM/DD/YY'),
        ticketId: ticketData?.Ticket.TId, //todo switch all of these over to query selectors and not state
        latitude: ticketData?.Latitude,
        longitude: ticketData?.Longitude,
        address: ticketData?.Ticket.ClientAddress,
        status: ticketData?.Status.Name,
        assignee: ticketData?.Assignee.Name,
        markerColor: ticketData?.Status.Color,
        criticalPriority: ticketData?.Ticket.Priority && ticketData?.Ticket.Priority === 'critical',
      },
    ];

    const map = useMap();

    function intToRGB(colorInt: number) {
      const b = (colorInt >> 16) & 255;
      const g = (colorInt >> 8) & 255;
      const r = colorInt & 255;

      return `rgb(${r}, ${g}, ${b})`;
    }

    const customIcon = (color: number, criticalPriority: boolean) => {
      const borderStyle = criticalPriority ? 'stroke-width: 2; stroke: red;' : '';
      return L.icon({
        iconUrl: `data:image/svg+xml;charset=UTF-8,
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'>
            <!-- Outer Border with padding -->
            <path d='M13 1C8.13 1 4 5.13 4 10c0 5.25 9 15 9 15s9-9.75 9-15c0-4.87-4.13-9-9-9z' style='${borderStyle}' fill='none'/>
            <!-- Marker -->
            <path fill='${intToRGB(color)}' d='M13 3C9.13 3 6 6.13 6 10c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S11.62 7.5 13 7.5 15.5 8.62 15.5 10 14.38 12.5 13 12.5z'/>
          </svg>`,
        iconSize: [41, 41],
        iconAnchor: [20.5, 41],
        popupAnchor: [1, -41],
      });
    };

    useEffect(() => {
      const markerMap: { [key: string]: L.Marker } = {};
      const bounds = L.latLngBounds([]);

      markerData1.forEach((data) => {
        const { dueDate, ticketId, latitude, longitude, address, markerColor, criticalPriority, status, assignee } =
          data;

        console.log('lat: ' + latitude + ' long: ' + longitude);

        let formattedDate = '';
        let dateStyle = {};

        const dueDateObj = dayjs(dueDate);
        if (dueDate.trim().toLowerCase() !== '0001-01-01t00:00:00z' && dueDateObj.isValid()) {
          const currentDate = dayjs();
          formattedDate = dueDateObj.format('MM/DD/YY');
          if (dueDateObj.isBefore(currentDate)) {
            dateStyle = 'red';
          }
        } else {
          formattedDate = ''; // Return an empty string for invalid dates
        }

        if (latitude != null && longitude != null && latitude !== 0 && longitude !== 0) {
          const marker = L.marker([latitude, longitude], { icon: customIcon(markerColor, criticalPriority) });

          (marker as any).ticketId = ticketId;
          (marker as any).status = status;
          (marker as any).assignee = assignee;

          const popupContent = `
            <div>
              <p><b>Ticket Id:</b> <a href="ticket.html?TicketId=${ticketId}">${ticketId}</a></p>
              <p><b>Address:</b> ${address}</p>
              <p><b>Due Date:</b> <span style="color: ${dateStyle || 'black'};">${formattedDate}</span></p>
              <p><b>Assigned To:</b> ${assignee}</p>
              <p><b>Status:</b> ${status}</p>
            </div>`;
          marker.bindPopup(popupContent);

          marker.addTo(map);
          markerMap[ticketId] = marker;
          bounds.extend(marker.getLatLng());
        }
      });

      if (bounds.isValid()) {
        map.fitBounds(bounds, { padding: [50, 50] });
      }
      if (!bounds.isValid()) {
        map.setView([40.235077, -99.121838], 5);
      }
    }, [map]);

    return null;
  };

  const handleClose = () => {
    updateTicketList({ ticketDetailMapOpen: false });
  };

  const LayerChangeHandler = ({ onLayerChange }: LayerChangeHandlerProps): null => {
    const map = useMap();

    useEffect(() => {
      const handleLayerChange = (e: any) => {
        if (e.name) {
          onLayerChange(e.name);
        }
      };

      map.on('baselayerchange', handleLayerChange);

      return () => {
        map.off('baselayerchange', handleLayerChange);
      };
    }, [map, onLayerChange]);

    return null;
  };

  const handleLayerChange = (name: any) => {
    setSelectedLayer(name);
  };

  const addLocateControl = (map: L.Map, locateControlRef: React.MutableRefObject<L.Control.Locate | null>) => {
    if (!locateControlRef.current) {
      const locateControl = L.control.locate().addTo(map);
      locateControlRef.current = locateControl;
    }
  };

  // const loadingSpinner = () => {
  //   return (
  //     <Box sx={{ display: 'flex', zIndex: 2000, position: 'absolute', top: '50%', left: '50%' }} >
  //       <CircularProgress sx={{ color: 'rgb(255, 193, 7)' }}/>
  //     </Box>
  //   );
  // }

  useEffect(() => {
    if (ticketListState.ticketDetailMapOpen) {
      console.log('ticketListState.ticketDetailMapOpen', ticketListState.ticketDetailMapOpen);
      if (!ticketData?.Latitude || !ticketData?.Longitude) {
        alert('No coordinates found\nUpdate address and save to refresh coordinates');
      } else {
        const intervalId = setInterval(() => {
          const map = mapRef.current;
          if (map) {
            addLocateControl(map, locateControlRef);
            clearInterval(intervalId);
          }
        }, 100);
        return () => clearInterval(intervalId);
      }
    }
  }, [ticketListState.ticketDetailMapOpen]);

  useEffect(() => {
    localStorage.setItem('selectedLayer', selectedLayer);
  }, [selectedLayer]);

  useEffect(() => {
    const checkStatusSelectLoaded = () => {
      const statusSelect = document.querySelector('[data-field="status"] select.TicketStatus') as HTMLSelectElement;

      if (
        statusSelect &&
        statusSelect.options[statusSelect.selectedIndex] &&
        statusSelect.options[statusSelect.selectedIndex].text
      ) {
        const selectedStatus = statusSelect.options[statusSelect.selectedIndex].text;

        clearInterval(intervalId);

        // const tdMapButton = document.getElementById("td-map") as HTMLButtonElement;
        // if (tdMapButton) {
        //   tdMapButton.addEventListener("click", handleOpen);
        //   tdMapButton.disabled = false;
        // }
      }
    };

    const intervalId = setInterval(checkStatusSelectLoaded, 100);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Modal
      open={ticketListState.ticketDetailMapOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box
        id="modal-container"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          height: '90%',
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* {loading && loadingSpinner()} */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: 'black',
            zIndex: '1000',
            backgroundColor: 'white',
            borderRadius: '4px',
            width: '48px',
            height: '48px',
            '&:hover': {
              backgroundColor: 'white',
            },
            border: '2px solid rgba(0, 0, 0, 0.2)',
            backgroundClip: 'padding-box',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ flex: 1, position: 'relative' }}>
          <MapContainer
            center={[40.235077, -99.121838]}
            zoom={5}
            ref={mapRef}
            style={{ height: '100%', width: '100%' }}
            maxZoom={20}
          >
            <LayersControl position="topright">
              <BaseLayer checked={selectedLayer === 'Google Hybrid'} name="Google Hybrid">
                <TileLayer url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" maxZoom={20} />
              </BaseLayer>
              <BaseLayer checked={selectedLayer === 'Google Satellite'} name="Google Satellite">
                <TileLayer url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" maxZoom={20} />
              </BaseLayer>
              <BaseLayer checked={selectedLayer === 'OpenStreetMap'} name="OpenStreetMap">
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={20} />
              </BaseLayer>
            </LayersControl>
            <LayerChangeHandler onLayerChange={handleLayerChange} />
            {ticketListState.ticketDetailMapOpen &&
              ticketData &&
              ticketData.Latitude !== null &&
              ticketData.Longitude !== null && (
                <MapMarkers
                // ticketId={ticketData?.Ticket.TId}
                // latitude={ticketData?.Latitude}
                // longitude={ticketData?.Longitude}
                />
              )}
          </MapContainer>
        </Box>
      </Box>
    </Modal>
  );
};

// @ts-ignore
// window.R_TicketDetailMap = {
//   init: (selector: string) => {
//     const domContainer = document.querySelector(`#${selector}`);
//     if (domContainer) {
//       const root = ReactDOM.createRoot(domContainer!);
//       root.render(<TicketDetailMap />);
//     }
//   },
// };

export default TicketDetailMap;
