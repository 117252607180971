import React, { useEffect, useState } from 'react'
import NumberFieldInput from '@/components/Atoms/TextField/NumberField.component'
// import SingleDropdown from '../Molecules/Dropdowns/Singledropdown.component'
// import { createOptionArrayFromArr } from '../../util/createOptionArray.util'
// import TextField from '../Atoms/TextField/TextField.component'
// import CheckboxInput from '../Atoms/Checkbox/CheckboxInput.component'
// import DateFieldInput from '../Molecules/Date/DateField.component'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { AccordionDetails, Box, Button, Checkbox, Grid2 as Grid, Select, setRef, Typography } from '@mui/material'
// import AccordionButton from '../Atoms/Accordion/Accordion.component'
import { useForm, FormProvider, useFieldArray, useFormContext, Controller } from "react-hook-form";
import useTicketList from '@/contexts/stores/ticketList.store';
import useTicket from '@/hooks/fetches/useTicket.service';
import { Accordion, AccordionSummary, AutoComplete, TextField } from '@utilisourcepackagelibdev/utilisourcepackagelib';
// import Checkbox from '@/components/Molecules/Checkbox/Checkbox.component'
import TextBox from '@/components/Molecules/Textbox/Textbox.component'
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import TextBox from "../Molecules/Textbox/Textbox.component";
// import Checkbox from "../Molecules/Checkbox/Checkbox.component";


interface typeinfo {
  ClassBinding: string
}

export function CFTypeIdDetail(id: number): typeinfo {
  switch (id) {
    case 16: return { ClassBinding: "cfTInteger" }
    case 17: return { ClassBinding: "cfTString" }
    case 18: return { ClassBinding: "cfTFile" }
    case 19: return { ClassBinding: "cfTDropdown" }
    case 20: return { ClassBinding: "cfTToggle" }
    case 21: return { ClassBinding: "cfTArcGIS" }
    case 22: return { ClassBinding: "cfTDate" }
  }
  throw new Error('Not a valid typeid')
}

export const checkType = (parent: any) => {
  let typeInfo = CFTypeIdDetail(parent.ValueMeta.C.TypeId).ClassBinding

  switch (typeInfo) {
    case 'cfTInteger':
      // console.log('cfTInteger')
      return parent.Value.MyInt

    case 'cfTDropdown':
      // console.log('cfTDropdown')
      return parent.Value.SelectedOpt

    case 'cfTString':
      // console.log('cfTString')
      return parent.Value.Str

    case 'cfTToggle':
      // console.log('cfTToggle')
      return parent.Value.Checked

    case 'cfTDate':
      // console.log('cfTDate')
      return parent.Value.TimeVal

    default:
      console.log('other default')
      break;
  }
}

const CustomFields = (props: any) => {
  // const { id } = useParams();
  // const id = '45642'
  // const [customFields, setCustomFields] = useState<any>(null);
  const [groupSummary, setGroupSummary] = useState<any>(null);
  const [parentFieldsArray, setParentFieldsArray] = useState<any>(null);
  const [finalArray, setFinalArray] = useState<any>(null);
  const [watchedParentValues, setWatchedParentValues] = useState<any>({});
  const [customFieldsReady, setCustomFieldsReady] = useState(false);
  const renderedFieldsSet = new Set(); // This will track rendered fields globally
  const [customFieldValuesData, setCustomFieldValuesData] = useState<any>(null);
  const [requiredFields, setRequiredFields] = useState<any>({});
  const [requiredFieldsCount, setRequiredFieldsCount] = useState<Record<number, number>>({});
  // const { register, control, handleSubmit, formState: { errors }, watch } = useForm();
  // const methods = useForm();

  const { ticketListState, updateTicketList } = useTicketList();
  const methods = useFormContext();
  const { formState, control, watch } = methods;

  // const [expandedChild, setExpandedChild] = useState<string | false>(false);

  // const handleChildAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //   setExpandedChild(isExpanded ? panel : false);
  // };

  // console.log('ticketListState:', ticketListState);

  const {
    customFields,
    customFieldGroups
  } = useTicket(
    ticketListState?.selectedTicket?.id,
  );

  // console.log('customFields:', customFields);
  // console.log('customFieldGroups:', customFieldGroups);


  // const dynamicField = (field: any, methods: any, changer: any, parentValue?: any, parentFieldId?: any) => {
  //   // const parentFieldValue = methods.watch(parentFieldId)
  //   const parentFieldValue = methods.watch(parentFieldId)
  //   // console.log(parentFieldValue)
  //   let typeInfo = CFTypeIdDetail(field.ValueMeta.C.TypeId).ClassBinding
  //   //! add is required to these fields
  //   //! add hover, last updated by and time?
  //   // switch (typeInfo) {
  //   //   case 'cfTInteger':
  //   //     // console.log('cfTInteger')
  //   //     return (
  //   //       <>
  //   //         {/* {methods.watch(`test.${field.ValueMeta.C.Meta.Cid}`) == field.valueMeta.C.Meta.Settings.ParentFieldValue ? */}
  //   //         <NumberFieldInput
  //   //           id={field.ValueMeta.C.Meta.Cid.toString()}
  //   //           value={field.Value.MyInt}
  //   //           placeholder={field.ValueMeta.C.Meta.Name}
  //   //           onDataChange={changer}
  //   //           label={field.ValueMeta.C.Meta.Name}
  //   //           min={field.ValueMeta.C.Type.Minimum}
  //   //           max={field.ValueMeta.C.Type.Maximum}
  //   //           step={field.ValueMeta.C.Type.Step}
  //   //           required
  //   //         />
  //   //         {/* : ""} */}
  //   //       </>
  //   //     )


  //   //   case 'cfTDropdown':
  //   //     // console.log('cfTDropdown')
  //   //     return (
  //   //       <SingleDropdown
  //   //         id={field.ValueMeta.C.Meta.Cid.toString()}
  //   //         name={field.ValueMeta.C.Meta.Name}
  //   //         options={createOptionArrayFromArr(field.ValueMeta.C.Type.OptLabels)}
  //   //         value={field.Value.SelectedOpt}
  //   //         label={field.ValueMeta.C.Meta.Name}
  //   //         onDataChange={changer}
  //   //       />
  //   //     )

  //   //   case 'cfTString':
  //   //     // console.log('cfTString')

  //   //     return (
  //   //       <>
  //   //         {/* <TextField
  //   //                             id={field.ValueMeta.C.Meta.Cid}
  //   //                             value={field.Value.Str}
  //   //                             placeholder={field.ValueMeta.C.Meta.Name}
  //   //                             onDataChange={changer}
  //   //                             label={field.ValueMeta.C.Meta.Name}
  //   //                             required={true}
  //   //                         // fullWidth
  //   //                         /> */}

  //   //         <TextBox
  //   //           id={field.ValueMeta.C.Meta.Cid.toString()}
  //   //           // value={field.Value.Str}
  //   //           placeholder={field.ValueMeta.C.Meta.Name}
  //   //           // onDataChange={changer}
  //   //           label={field.ValueMeta.C.Meta.Name}
  //   //           minLength={2}
  //   //           required

  //   //         // required
  //   //         // register={methods.register}

  //   //         // fullWidth
  //   //         />
  //   //       </>

  //   //     )

  //   //   case 'cfTToggle':
  //   //     return (
  //   //       <>
  //   //         {/* <CheckboxInput
  //   //                             id={field.ValueMeta.C.Meta.Cid}
  //   //                             label={field.ValueMeta.C.Meta.Name}
  //   //                             checked={field.Value.Checked}
  //   //                             onDataChange={changer}
  //   //                             required
  //   //                         /> */}
  //   //         <Checkbox
  //   //           id={field.ValueMeta.C.Meta.Cid.toString()}
  //   //           label={field.ValueMeta.C.Meta.Name}
  //   //           checked={field.Value.Checked}
  //   //           onDataChange={changer}
  //   //           required
  //   //         />
  //   //       </>
  //   //     )

  //   //   case 'cfTDate':
  //   //     // console.log('cfTDate')
  //   //     return (
  //   //       <DateFieldInput
  //   //         id={field.ValueMeta.C.Meta.Cid.toString()}
  //   //         label={field.ValueMeta.C.Meta.Name}
  //   //         value={field.Value.TimeVal}
  //   //         onDataChange={changer}
  //   //         required
  //   //       />
  //   //     )

  //   //   default:
  //   //     console.log('other default')
  //   //     break;
  //   // }
  // }

  // const dynamicChildField = (field: any, methods: any, changer: any, parentValue?: any, parentFieldId?: any) => {
  //   // const parentFieldValue = methods.watch(parentFieldId)
  //   const parentFieldValue = methods.watch(parentFieldId)
  //   // const parentFieldValue = watchedParentValues[parentFieldId];
  //   // console.log(parentFieldValue[parentFieldId])
  //   // console.log(field.ValueMeta.C.Meta.Settings.ParentFieldValue)
  //   // if (field.ValueMeta.C.Meta.Settings.ParentFieldValue == "Checked" && parentFieldValue[parentFieldId]) {
  //   //     console.log('checked')
  //   // }
  //   // console.warn(field.ValueMeta.C.Meta.Settings.ParentFieldValue === parentFieldValue[parentFieldId])
  //   let typeInfo = CFTypeIdDetail(field.ValueMeta.C.TypeId).ClassBinding
  //   //! add is required to these fields
  //   //! add hover, last updated by and time?
  //   // switch (typeInfo) {
  //   //   case 'cfTInteger':
  //   //     // console.log('cfTInteger')
  //   //     if (field.ValueMeta.C.Meta.Settings.ParentFieldValue == "Checked" && parentFieldValue[parentFieldId] || field.ValueMeta.C.Meta.Settings.ParentFieldValue === parentFieldValue[parentFieldId]) {
  //   //       return (
  //   //         <>
  //   //           {/* {methods.watch(`test.${field.ValueMeta.C.Meta.Cid}`) == field.valueMeta.C.Meta.Settings.ParentFieldValue ? */}
  //   //           <Grid item xs={12} sm={6} md={4}>
  //   //             <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //   //               <NumberFieldInput
  //   //                 id={field.ValueMeta.C.Meta.Cid.toString()}
  //   //                 value={field.Value.MyInt}
  //   //                 placeholder={field.ValueMeta.C.Meta.Name}
  //   //                 onDataChange={changer}
  //   //                 label={field.ValueMeta.C.Meta.Name}
  //   //                 min={field.ValueMeta.C.Type.Minimum}
  //   //                 max={field.ValueMeta.C.Type.Maximum}
  //   //                 step={field.ValueMeta.C.Type.Step}
  //   //                 required
  //   //               />
  //   //               {/* : ""} */}
  //   //             </Box>
  //   //           </Grid>
  //   //         </>
  //   //       )
  //   //     } else {
  //   //       return ""
  //   //     }

  //   //   case 'cfTDropdown':
  //   //     // console.log('cfTDropdown')
  //   //     if (field.ValueMeta.C.Meta.Settings.ParentFieldValue == "Checked" && parentFieldValue[parentFieldId] || field.ValueMeta.C.Meta.Settings.ParentFieldValue === parentFieldValue[parentFieldId]) {
  //   //       return (
  //   //         <>
  //   //           <Grid item xs={12} sm={6} md={4}>
  //   //             <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //   //               <SingleDropdown
  //   //                 id={field.ValueMeta.C.Meta.Cid.toString()}
  //   //                 name={field.ValueMeta.C.Meta.Name}
  //   //                 options={createOptionArrayFromArr(field.ValueMeta.C.Type.OptLabels)}
  //   //                 value={field.Value.SelectedOpt}
  //   //                 label={field.ValueMeta.C.Meta.Name}
  //   //                 onDataChange={changer}
  //   //               />
  //   //             </Box>
  //   //           </Grid>
  //   //         </>
  //   //       )
  //   //     } else {
  //   //       return ""
  //   //     }

  //   //   case 'cfTString':
  //   //     // console.log('cfTString')
  //   //     if (field.ValueMeta.C.Meta.Settings.ParentFieldValue == "Checked" && parentFieldValue[parentFieldId] || field.ValueMeta.C.Meta.Settings.ParentFieldValue === parentFieldValue[parentFieldId]) {

  //   //       return (
  //   //         <>
  //   //           {/* <TextField
  //   //                             id={field.ValueMeta.C.Meta.Cid}
  //   //                             value={field.Value.Str}
  //   //                             placeholder={field.ValueMeta.C.Meta.Name}
  //   //                             onDataChange={changer}
  //   //                             label={field.ValueMeta.C.Meta.Name}
  //   //                             required={true}
  //   //                         // fullWidth
  //   //                         /> */}
  //   //           <Grid item xs={12} sm={6} md={4}>
  //   //             <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //   //               <TextBox
  //   //                 id={field.ValueMeta.C.Meta.Cid.toString()}
  //   //                 // value={field.Value.Str}
  //   //                 placeholder={field.ValueMeta.C.Meta.Name}
  //   //                 // onDataChange={changer}
  //   //                 label={field.ValueMeta.C.Meta.Name}
  //   //                 minLength={2}
  //   //                 required

  //   //               // required
  //   //               // register={methods.register}

  //   //               // fullWidth
  //   //               />
  //   //             </Box>
  //   //           </Grid>
  //   //         </>

  //   //       )
  //   //     } else {
  //   //       return ""
  //   //     }

  //   //   case 'cfTToggle':
  //   //     if (field.ValueMeta.C.Meta.Settings.ParentFieldValue == "Checked" && parentFieldValue[parentFieldId] || field.ValueMeta.C.Meta.Settings.ParentFieldValue === parentFieldValue[parentFieldId]) {
  //   //       return (
  //   //         <>
  //   //           {/* <CheckboxInput
  //   //                             id={field.ValueMeta.C.Meta.Cid}
  //   //                             label={field.ValueMeta.C.Meta.Name}
  //   //                             checked={field.Value.Checked}
  //   //                             onDataChange={changer}
  //   //                             required
  //   //                         /> */}
  //   //           <Grid item xs={12} sm={6} md={4}>
  //   //             <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //   //               <Checkbox
  //   //                 id={field.ValueMeta.C.Meta.Cid.toString()}
  //   //                 label={field.ValueMeta.C.Meta.Name}
  //   //                 checked={field.Value.Checked}
  //   //                 onDataChange={changer}
  //   //                 required
  //   //               />
  //   //             </Box>
  //   //           </Grid>
  //   //         </>
  //   //       )
  //   //     } else {
  //   //       return ""
  //   //     }

  //   //   case 'cfTDate':
  //   //     // console.log('cfTDate')
  //   //     if (field.ValueMeta.C.Meta.Settings.ParentFieldValue == "Checked" && parentFieldValue[parentFieldId] || field.ValueMeta.C.Meta.Settings.ParentFieldValue === parentFieldValue[parentFieldId]) {
  //   //       return (
  //   //         <Grid item xs={12} sm={6} md={4}>
  //   //           <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>

  //   //             <DateFieldInput
  //   //               id={field.ValueMeta.C.Meta.Cid.toString()}
  //   //               label={field.ValueMeta.C.Meta.Name}
  //   //               value={field.Value.TimeVal}
  //   //               onDataChange={changer}
  //   //               required
  //   //             />
  //   //           </Box>
  //   //         </Grid>
  //   //       )
  //   //     } else {
  //   //       return ""
  //   //     }

  //   //   default:
  //   //     console.log('other default')
  //   //     break;
  //   // }
  // }

  // useEffect(() => {
  //     if (finalArray) {
  //       const newWatchedParentValues = finalArray.reduce((acc: any, parent: any) => {
  //         const parentFieldId = parent.ValueMeta.C.Meta.Cid;
  //         acc[parentFieldId] = methods.watch(parentFieldId);
  //         return acc;
  //       }, {});

  //       setWatchedParentValues(newWatchedParentValues);
  //     }
  //   }, [finalArray, methods]);

  // function getGroupSummary(Ticketid: any) {
  //   // getStatuses()
  //   const url =
  //     `http://localhost:8000/www/uapi/customfields?groupsummary=` + Ticketid;
  //   console.log(`${Date.now()}: getGroupSummary start TicketId=${Ticketid}`);
  //   axios.get(url)
  //     .then(async (response) => {
  //       console.log(`${Date.now()}: getGroupSummary response: `, response.data);
  //       setGroupSummary(response.data);
  //     })
  //     .catch(error => {
  //       console.error(`${Date.now()}: getGroupSummary error fetching group summary: `, error);
  //     })
  // };

  // function GetCFieldsAPICall(ticketid: string | undefined) {
  //   const url =
  //     `http://localhost:8000/www/uapi/customfields?ticketid=` + ticketid;
  //   axios.get(url)
  //     .then((response) => {
  //       console.log(`${Date.now()}: GetCFieldsAPICall response: `, response.data);
  //       setCustomFields(response.data);
  //       //!Immediately call groupedFields to build out the groups of parent children
  //       // groupedFields(response.data)
  //       groupedFields2(response.data)
  //     })
  //     .catch((error) => {
  //       console.error(`${Date.now()}: GetCFieldsAPICall error fetching custom fields: `, error);
  //     });
  // }

  // function groupedFields(data: any[], childFieldsArray?: any[]) {

  //   if (!data) {
  //     return [];
  //   }
  //   const groupFieldsArray = data.reduce((acc: any[], field: any) => {
  //     const parentId = field.ValueMeta.C.Meta.Settings.ParentFieldId;
  //     if (field.ValueMeta.C.Meta.ArchivedTime == "0001-01-01T00:00:00Z") {

  //       if (parentId === 0) {
  //         // This is a parent field, add it to the accumulator with an empty childFields array
  //         acc.push({
  //           ...field,
  //           // childFields: [],
  //           childFields: groupedFields(field.childFields),
  //         });
  //       } else {
  //         // This is a child field, find its parent in the accumulator and add it to the childFields array
  //         // 17, 26, 60, 61, 63, 64
  //         //"1st Transition Type" - parent checked 253 - type dropdown - 1st transition 
  //         //"Temp Drop Footage" - parent checked 231 - type number - Temp drop 
  //         //"2nd Transition Seq#" - parent checked 255 - type number - 2nd transition
  //         //"2nd Transition Type" - parent checked 255 - type dropdown - 2nd transition
  //         //"2nd Transition: Notes" - parent checked 255 - type string - 2nd transition
  //         //"1st Bore Notes" - parent checked 269 - type string - 1st bore - PARENT NOT SCOPED
  //         const parentIndex = acc.findIndex(item => item.ValueMeta.C.Meta.Cid === parentId);
  //         console.log(parentIndex)
  //         if (parentIndex !== -1) {
  //           // acc[parentIndex].childFields.push(field);
  //           acc[parentIndex].childFields.push({
  //             ...field,
  //             childFields: groupedFields(data, field.childFields),
  //           });
  //         } else {
  //           const parentIndex = customFields.findIndex((item: { ValueMeta: { C: { Meta: { Cid: any } } } }) => item.ValueMeta.C.Meta.Cid === parentId);
  //           console.log(parentIndex)
  //           console.warn(`Parent field ${parentId} not found for child field ${field.ValueMeta.C.Meta.Cid}  ${field.ValueMeta.C.Meta.Name}`);
  //         }
  //       }

  //     } else {
  //       console.log("archived" + field.ValueMeta.C.Meta.Name)

  //     }
  //     return acc;
  //   }, []);

  //   console.log(groupFieldsArray)
  //   setParentFieldsArray(groupFieldsArray)
  //   setFinalArray(groupFieldsArray)
  //   // setChildFieldsArray(groupFieldsArray)
  //   return groupFieldsArray;
  // }

  //! this one works!!!

  // function groupedFields2(data: any[]) {
  //   // Separate parent and child fields
  //   const parentFields = data.filter(field => field.ValueMeta.C.Meta.Settings.ParentFieldId === 0);
  //   const childFields = data.filter(field => field.ValueMeta.C.Meta.Settings.ParentFieldId !== 0);

  //   console.log(parentFields)
  //   console.log(childFields)
  //   // Process parent fields
  //   const groupFieldsArray = parentFields.map(field => ({
  //     ...field,
  //     childFields: [],
  //   }));

  //   const groupFieldsArray2 = childFields.map(field => ({
  //     ...field,
  //     childFields: [],
  //   }));

  //   console.log(groupFieldsArray)

  //   // child^2 first attach all the child fields to the children of their parent fields
  //   childFields.forEach(field => {
  //     const parentId = field.ValueMeta.C.Meta.Settings.ParentFieldId;
  //     const childIndex = groupFieldsArray2.findIndex(item => item.ValueMeta.C.Meta.Cid === parentId);
  //     if (childIndex !== -1) {
  //       groupFieldsArray2[childIndex].childFields.push({
  //         ...field,
  //         childFields: [],  // Initialize childFields for this child field
  //       });
  //     }
  //   });

  //   // this is an array of all the children that need to be attached to their parent 
  //   console.warn(groupFieldsArray2)


  //   // Process child fields
  //   groupFieldsArray2.forEach(field => {
  //     const parentId = field.ValueMeta.C.Meta.Settings.ParentFieldId;
  //     const parentIndex = groupFieldsArray.findIndex(item => item.ValueMeta.C.Meta.Cid === parentId);

  //     if (parentIndex !== -1) {
  //       groupFieldsArray[parentIndex].childFields.push({
  //         ...field
  //       });
  //     } else {

  //     }
  //   });

  //   console.log(groupFieldsArray)
  //   console.log(groupFieldsArray2)
  //   setParentFieldsArray(groupFieldsArray)
  //   setFinalArray(groupFieldsArray)
  //   return groupFieldsArray;
  // }

  // useEffect(() => {
  //   // setCustomFields(null)
  //   setGroupSummary(null)
  //   // getGroupSummary(id)
  //   // GetCFieldsAPICall(id)
  //   // console.log(customFields && groupedFields2(customFields))
  //   console.log(props)
  // }, [id])

  // const changer = (e: any, value: any) => {
  //   console.log("id " + e + ": ", value)
  // }

  // const onSubmit = (data: any) => {
  //   console.log(data)
  //   // console.log(fields)
  //   // console.log(controlledFields)
  //   return data;
  // }

  // const createGroupAccordions = () => { //! not using

  //   return customFieldGroups?.data.map((group: any) => {
  //     console.log('group:', group)
  //     return (
  //       <Accordion
  //         key={group.FieldGroupId}
  //       // name = { group.GroupName + ` (${group.FieldsInGroup})` } 
  //       >
  //         <div>
  //           <Grid item xs={12}>
  //             {/* <FormProvider {...methods}>
  //               <form onSubmit={methods.handleSubmit(onSubmit)}> */}
  //             <Grid key={group.FieldGroupId} container spacing={3} display={"flex"} flexWrap={"wrap"}>
  //               {
  //                 finalArray && finalArray.map((parent: any) => {
  //                   if (parent.ValueMeta.C.Meta.Settings.FieldGroupId === group.FieldGroupId) {
  //                     return (
  //                       <>
  //                         <Grid item xs={12} sm={6} md={4}>
  //                           <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //                             {/* <input {...register("firstName", { required: true })} /> */}
  //                             {dynamicField(parent, methods, changer)}
  //                           </Box>
  //                         </Grid>
  //                         {parent.childFields.map((child: any) => {
  //                           // if (child.childFields.length > 0) {
  //                           //     {
  //                           //         child.childFields.map((childOfChild: any) => {
  //                           //             return (
  //                           //                 <>
  //                           //                     {dynamicChildField(childOfChild, methods, changer, checkType(parent), parent.ValueMeta.C.Meta.Cid)
  //                           //                     }
  //                           //                 </>

  //                           //             )
  //                           //         })
  //                           //     }
  //                           // }
  //                           return (
  //                             <>
  //                               {dynamicChildField(child, methods, changer, checkType(parent), parent.ValueMeta.C.Meta.Cid)
  //                               }
  //                             </>

  //                           )
  //                         })}
  //                       </>
  //                     )
  //                   }
  //                 })
  //               }
  //             </Grid>
  //             {/* </form>
  //             </FormProvider> */}
  //           </Grid >
  //         </div >
  //       </Accordion >
  //     )
  //   })
  // }

  // const buildCustomFieldInputs = (sortedFields: any) => { //! not using
  //   // console.log('sortedFields:', sortedFields)

  //   return (
  //     sortedFields.map((cfield: any) => {
  //       // console.log('cfield:', cfield)
  //       // console.log('cfield.ValueMeta.C.Meta.Name:', cfield.ValueMeta.C.Meta.Name)
  //       // console.log('cfield.Value:', cfield.Value)

  //       let typeInfo = CFTypeIdDetail(cfield.ValueMeta.C.TypeId).ClassBinding
  //       // console.log('typeInfo:', typeInfo)


  //       switch (typeInfo) {
  //         case 'cfTInteger':
  //           // console.log('cfTInteger')
  //           // if (field.ValueMeta.C.Meta.Settings.ParentFieldValue == "Checked" && parentFieldValue[parentFieldId] || field.ValueMeta.C.Meta.Settings.ParentFieldValue === parentFieldValue[parentFieldId]) {
  //           return (
  //             <>
  //               {/* {methods.watch(`test.${field.ValueMeta.C.Meta.Cid}`) == field.valueMeta.C.Meta.Settings.ParentFieldValue ? */}
  //               <Grid size={{ xs: 6, sm: 6, md: 6 }} key={cfield.ValueMeta.C.Meta.Cid.toString()}>
  //                 <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //                   <Controller
  //                     name={cfield.ValueMeta.C.Meta.Cid.toString()}
  //                     control={control}
  //                     defaultValue={cfield.Value.MyInt || 0}  // Set the default value for this field
  //                     render={({ field }) => {
  //                       // console.log('inside field:', field);  // This will now contain the correct value

  //                       return (
  //                         <NumberFieldInput
  //                           {...field}  // Spread form control props (like name, value, onChange, etc.)
  //                           id={cfield.ValueMeta.C.Meta.Cid.toString()}
  //                           placeholder={cfield.ValueMeta.C.Meta.Name}
  //                           onDataChange={changer}
  //                           label={cfield.ValueMeta.C.Meta.Name}
  //                           min={cfield.ValueMeta.C.Type.Minimum}
  //                           max={cfield.ValueMeta.C.Type.Maximum}
  //                           step={cfield.ValueMeta.C.Type.Step}
  //                           required={cfield.ValueMeta.C.Meta.Settings.IsRequired}
  //                         />
  //                       );
  //                     }}
  //                   />
  //                 </Box>
  //               </Grid>
  //             </>
  //           )
  //         // } else {
  //         //   return ""
  //         // }

  //         case 'cfTDropdown':
  //           // console.log('cfTDropdown')
  //           // if (field.ValueMeta.C.Meta.Settings.ParentFieldValue == "Checked" && parentFieldValue[parentFieldId] || field.ValueMeta.C.Meta.Settings.ParentFieldValue === parentFieldValue[parentFieldId]) {
  //           return (
  //             <>
  //               <Grid size={{ xs: 6, sm: 6, md: 6 }}>
  //                 <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //                   {/* <SingleDropdown
  //                     id={field.ValueMeta.C.Meta.Cid.toString()}
  //                     name={field.ValueMeta.C.Meta.Name}
  //                     options={createOptionArrayFromArr(field.ValueMeta.C.Type.OptLabels)}
  //                     value={field.Value.SelectedOpt}
  //                     label={field.ValueMeta.C.Meta.Name}
  //                     onDataChange={changer}
  //                   /> */}
  //                   <AutoComplete
  //                     id={cfield.ValueMeta.C.Meta.Cid.toString()}
  //                     options={
  //                       cfield.ValueMeta.C.Type.OptLabels.map((data: any, index: number) => ({ //todo add a type
  //                         id: index,
  //                         label: data,
  //                       })) || []
  //                     }
  //                     // options={ [] }
  //                     loading={cfield.ValueMeta.C.Type.OptLabels?.isPending}
  //                     autocompleteProps={{
  //                       id: 'assignee',
  //                       loadingText: `Loading ${cfield.ValueMeta.C.Meta.Name} Options...`,
  //                       size: 'small'
  //                     }}
  //                     multiple={false}
  //                     label={cfield.ValueMeta.C.Meta.Name}
  //                     required={cfield.ValueMeta.C.Meta.Settings.IsRequired}
  //                   />
  //                 </Box>
  //               </Grid>
  //             </>
  //           )
  //         // } else {
  //         //   return ""
  //         // }

  //         case 'cfTString':
  //           // console.log('cfTString')
  //           // if (field.ValueMeta.C.Meta.Settings.ParentFieldValue == "Checked" && parentFieldValue[parentFieldId] || field.ValueMeta.C.Meta.Settings.ParentFieldValue === parentFieldValue[parentFieldId]) {

  //           return (
  //             <>
  //               {/* <TextField
  //                           id={field.ValueMeta.C.Meta.Cid}
  //                           value={field.Value.Str}
  //                           placeholder={field.ValueMeta.C.Meta.Name}
  //                           onDataChange={changer}
  //                           label={field.ValueMeta.C.Meta.Name}
  //                           required={true}
  //                       // fullWidth
  //                       /> */}
  //               {/* <Grid item xs={12} sm={6} md={4}>
  //                 <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //                   <TextBox
  //                     id={field.ValueMeta.C.Meta.Cid.toString()}
  //                     // value={field.Value.Str}
  //                     placeholder={field.ValueMeta.C.Meta.Name}
  //                     // onDataChange={changer}
  //                     label={field.ValueMeta.C.Meta.Name}
  //                     minLength={2}
  //                     required

  //                   // required
  //                   // register={methods.register}

  //                   // fullWidth
  //                   />
  //                 </Box>
  //               </Grid> */}
  //               <Grid size={{ xs: 6, sm: 6, md: 6 }}>
  //                 <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //                   <TextBox
  //                     name={cfield.ValueMeta.C.Meta.Cid.toString()}
  //                     control={control}
  //                     label={cfield.ValueMeta.C.Meta.Name}
  //                     size="small"
  //                     fullWidth
  //                     required={cfield.ValueMeta.C.Meta.Settings.IsRequired}
  //                   />
  //                 </Box>
  //               </Grid>
  //             </>

  //           )
  //         // } else {
  //         //   return ""
  //         // }

  //         case 'cfTToggle':
  //           // console.log('cfTToggle')
  //           // if (field.ValueMeta.C.Meta.Settings.ParentFieldValue == "Checked" && parentFieldValue[parentFieldId] || field.ValueMeta.C.Meta.Settings.ParentFieldValue === parentFieldValue[parentFieldId]) {
  //           return (
  //             //     <>
  //             //       {/* <CheckboxInput
  //             //                   id={field.ValueMeta.C.Meta.Cid}
  //             //                   label={field.ValueMeta.C.Meta.Name}
  //             //                   checked={field.Value.Checked}
  //             //                   onDataChange={changer}
  //             //                   required
  //             //               /> */}
  //             //       <Grid item xs={12} sm={6} md={4}>
  //             //         <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //             //           <Checkbox
  //             //             id={field.ValueMeta.C.Meta.Cid.toString()}
  //             //             label={field.ValueMeta.C.Meta.Name}
  //             //             checked={field.Value.Checked}
  //             //             onDataChange={changer}
  //             //             required
  //             //           />
  //             //         </Box>
  //             //       </Grid>
  //             //     </>
  //             <Grid size={{ xs: 6, sm: 6, md: 6 }} key={cfield.ValueMeta.C.Meta.Cid.toString()}>
  //               <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //                 <Controller
  //                   name={cfield.ValueMeta.C.Meta.Cid.toString()}
  //                   control={control}
  //                   // defaultValue={cfield.Value.MyInt || 0}  // Set the default value for this field
  //                   render={({ field }) => {
  //                     // console.log('inside field:', field);  // This will now contain the correct value

  //                     return (
  //                       <Checkbox
  //                         {...field}
  //                         id={cfield.ValueMeta.C.Meta.Cid.toString()}
  //                         // label={cfield.ValueMeta.C.Meta.Name}
  //                         checked={field.value}
  //                         // onDataChange={() => null}
  //                         required={cfield.ValueMeta.C.Meta.Settings.IsRequired}
  //                       />
  //                     );
  //                   }}
  //                 />
  //               </Box>
  //             </Grid>
  //           )
  //         // } else {
  //         //   return ""
  //         // }

  //         case 'cfTDate':
  //           // console.log('cfTDate')
  //           // if (field.ValueMeta.C.Meta.Settings.ParentFieldValue == "Checked" && parentFieldValue[parentFieldId] || field.ValueMeta.C.Meta.Settings.ParentFieldValue === parentFieldValue[parentFieldId]) {
  //           return (
  //             //     <Grid item xs={12} sm={6} md={4}>
  //             //       <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>

  //             //         <DateFieldInput
  //             //           id={field.ValueMeta.C.Meta.Cid.toString()}
  //             //           label={field.ValueMeta.C.Meta.Name}
  //             //           value={field.Value.TimeVal}
  //             //           onDataChange={changer}
  //             //           required
  //             //         />
  //             //       </Box>
  //             //     </Grid>
  //             <Grid size={{ xs: 6, sm: 6, md: 6 }}>
  //               <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //                 <Controller
  //                   name={cfield.ValueMeta.C.Meta.Cid.toString()}
  //                   control={control}
  //                   defaultValue={null}
  //                   render={({ field }) => {
  //                     if (cfield.ValueMeta.C.Type.IncludeTime) {
  //                       return (
  //                         <DateTimePicker
  //                           {...field}
  //                           label={cfield.ValueMeta.C.Meta.Name}
  //                           onChange={(newValue) => field.onChange(newValue)}
  //                           slotProps={{
  //                             textField: {
  //                               fullWidth: true,
  //                               size: 'small',
  //                             },
  //                           }}
  //                         />
  //                       )
  //                     } else {
  //                       return (
  //                         <DatePicker
  //                           {...field}
  //                           label={cfield.ValueMeta.C.Meta.Name}
  //                           onChange={(newValue) => field.onChange(newValue)}
  //                           slotProps={{
  //                             textField: {
  //                               fullWidth: true,
  //                               size: 'small',
  //                             },
  //                           }}
  //                         />
  //                       )
  //                     }
  //                   }}
  //                 />
  //               </Box>
  //             </Grid>
  //           )
  //         // } else {
  //         //   return ""
  //         // }

  //         default:
  //           console.log('other default')
  //           break;
  //       }
  //     })
  //   )
  // }

  const checkAncestorsVisibility = (childField: any, allFields: any): any => {
    // This function checks if the child field should be visible based on its ancestors.
    // So if the parent is hidden, both the child and grandchilds will be hidden and so on.
    const parentFieldId = childField.ValueMeta.C.Meta.Settings.ParentFieldId;
    const parentFieldValue = childField.ValueMeta.C.Meta.Settings.ParentFieldValue
    const parentInputValue = watch(parentFieldId.toString());

    if (
      (parentFieldValue === "Has value" && parentInputValue) ||
      (parentFieldValue === "Has no value" && !parentInputValue) ||
      (parentFieldValue === "Checked" && parentInputValue === true) ||
      (parentFieldValue === "Unchecked" && parentInputValue === false) ||
      (parentFieldValue === "any") ||
      (parentFieldValue.includes('Greater than') && (parentInputValue > parseInt(parentFieldValue.match(/Greater than (\d+)/)[1], 10))) ||
      (parentFieldValue.includes('Less than') && (parentInputValue < parseInt(parentFieldValue.match(/Less than (\d+)/)[1], 10))) ||
      (parentFieldValue.includes('Equals') && (parentInputValue == parentFieldValue.match(/Equals (\d+)/)[1])) ||
      (parentInputValue && typeof parentInputValue === 'object' && parentInputValue.label && parentInputValue.label === parentFieldValue) ||
      (!parentFieldId)
    ) {
      // Recursively check if the parent should be visible.
      const parentField = allFields.find((field: any) => field.ValueMeta.C.Meta.Cid === parentFieldId);
      return parentField ? checkAncestorsVisibility(parentField, allFields) : true;
    }
  };

  const renderFieldWithChildren = (childField: any, allFields: any, currentGroupId: number) => {
    // This function renders a field and its children if they should be visible.

    // console.log('childField.ValueMeta.C.Meta.Name:', childField.ValueMeta.C.Meta.Name)

    // const { watch } = useFormContext();

    // Check if the field belongs to the current group
    if (childField.ValueMeta.C.Meta.Settings.FieldGroupId !== currentGroupId) {
      return null;
    }

    // Determine if the field and all its ancestors should be visible
    const shouldRender = checkAncestorsVisibility(childField, allFields);

    if (!shouldRender) return null; // If not visible, don't render this field or its children.

    // Check if this field has already been rendered
    if (renderedFieldsSet.has(childField.ValueMeta.C.Meta.Cid)) {
      return null; // If already rendered, don't render it again
    }

    // Mark the field as rendered
    renderedFieldsSet.add(childField.ValueMeta.C.Meta.Cid);

    // Recursively find children of this field
    const childFields = allFields.filter(
      (field: any) => field.ValueMeta.C.Meta.Settings.ParentFieldId === childField.ValueMeta.C.Meta.Cid
    );

    // if (childField.ValueMeta?.C?.Meta?.Settings.IsRequired) {
    //   const requiredInputValue = watch(childField.ValueMeta?.C?.Meta?.Cid.toString());

    //   useEffect(() => {
    //     setTheRequiredFieldCount(childField, requiredInputValue);
    //   }, [requiredInputValue]);
    // }

    return (
      <>
        {/* Render the field */}
        {renderField(childField)}

        {/* Recursively render its child fields */}
        {childFields.map((field: any) => renderFieldWithChildren(field, allFields, currentGroupId))}
      </>
    );
  };

  const renderField = (childField: any) => {
    let typeInfo = CFTypeIdDetail(childField.ValueMeta.C.TypeId).ClassBinding;

    switch (typeInfo) {
      case 'cfTInteger':
        return (
          <Grid size={{ xs: 6, sm: 6, md: 6 }} key={childField.ValueMeta.C.Meta.Cid.toString()}>
            <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
              <Controller
                name={childField.ValueMeta.C.Meta.Cid.toString()}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <NumberFieldInput
                    {...field}
                    id={childField.ValueMeta.C.Meta.Cid.toString()}
                    placeholder={childField.ValueMeta.C.Meta.Name}
                    onDataChange={(newValue: any) => field.onChange(newValue)}
                    label={childField.ValueMeta.C.Meta.Name}
                    min={childField.ValueMeta.C.Type.Minimum}
                    max={childField.ValueMeta.C.Type.Maximum}
                    step={childField.ValueMeta.C.Type.Step}
                    required={childField.ValueMeta.C.Meta.Settings.IsRequired}
                  />
                )}
              />
            </Box>
          </Grid>
        );

      case 'cfTDropdown':
        return (
          <Grid size={{ xs: 6, sm: 6, md: 6 }}>
            <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
              <AutoComplete
                id={childField.ValueMeta.C.Meta.Cid.toString()}
                options={childField.ValueMeta.C.Type.OptLabels.map((data: any, index: number) => ({ id: index, label: data })) || []}
                loading={childField.ValueMeta.C.Type.OptLabels?.isPending}
                autocompleteProps={{
                  id: 'assignee',
                  loadingText: `Loading ${childField.ValueMeta.C.Meta.Name} Options...`,
                  size: 'small'
                }}
                multiple={false}
                label={childField.ValueMeta.C.Meta.Name}
                required={childField.ValueMeta.C.Meta.Settings.IsRequired}
              />
            </Box>
          </Grid>
        );

      case 'cfTString':
        return (
          <Grid size={{ xs: 6, sm: 6, md: 6 }}>
            <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
              <TextBox
                name={childField.ValueMeta.C.Meta.Cid.toString()}
                control={control}
                label={childField.ValueMeta.C.Meta.Name}
                size="small"
                fullWidth
                required={childField.ValueMeta.C.Meta.Settings.IsRequired}
              />
            </Box>
          </Grid>
        );

      case 'cfTToggle':
        return (
          <Grid size={{ xs: 6, sm: 12, md: 12 }} key={childField.ValueMeta.C.Meta.Cid.toString()}>
            <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
              <Controller
                name={childField.ValueMeta.C.Meta.Cid.toString()}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <Grid display="flex" alignItems="center">
                    <Checkbox
                      {...field}
                      id={childField.ValueMeta.C.Meta.Cid.toString()}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      required={childField.ValueMeta.C.Meta.Settings.IsRequired}
                    />
                    <Typography ml={1}>{childField.ValueMeta.C.Meta.Name}</Typography>
                  </Grid>
                )}
              />
            </Box>
          </Grid>
        );

      case 'cfTDate':
        return (
          <Grid size={{ xs: 6, sm: 6, md: 6 }}>
            <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
              <Controller
                name={childField.ValueMeta.C.Meta.Cid.toString()}
                control={control}
                defaultValue={null}
                render={({ field }) => {
                  if (childField.ValueMeta.C.Type.IncludeTime) {
                    return (
                      <DateTimePicker
                        {...field}
                        label={childField.ValueMeta.C.Meta.Name}
                        onChange={(newValue) => field.onChange(newValue)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: 'small',
                          },
                        }}
                      />
                    );
                  } else {
                    return (
                      <DatePicker
                        {...field}
                        label={childField.ValueMeta.C.Meta.Name}
                        onChange={(newValue) => field.onChange(newValue)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            size: 'small',
                          },
                        }}
                      />
                    );
                  }
                }}
              />
            </Box>
          </Grid>
        );

      default:
        return null;
    }
  };

  const buildCustomFieldInputs2 = (groupFields: any, allFields: any, currentGroupId: number) => {
    return groupFields.map((field: any) => renderFieldWithChildren(field, allFields, currentGroupId));
  };

  // const createGroupAccordions2 = () => { //? this is the original
  //   const groupedFields: Record<number, { groupName: string, fields: any[] }> = {};

  //   customFields?.data?.forEach((field: any) => {
  //     const fieldGroupId: number = field.ValueMeta?.C?.Meta?.Settings?.FieldGroupId;

  //     if (!groupedFields[fieldGroupId]) {
  //       const groupName = customFieldGroups?.data.find((group: any) => group.FieldGroupId === fieldGroupId)?.GroupName || 'Unknown Group';
  //       groupedFields[fieldGroupId] = {
  //         groupName: groupName,
  //         fields: []
  //       };
  //     }

  //     groupedFields[fieldGroupId].fields.push(field);
  //   });

  //   const allFields = Object.values(groupedFields).reduce((acc: any[], group) => acc.concat(group.fields), []);

  //   return customFieldGroups?.data.map((group: any) => {
  //     const sortedFields = (groupedFields[group.FieldGroupId]?.fields || []).sort((a: any, b: any) => {
  //       const nameA = a.ValueMeta?.C?.Meta?.Name?.toLowerCase() || '';
  //       const nameB = b.ValueMeta?.C?.Meta?.Name?.toLowerCase() || '';
  //       return nameA.localeCompare(nameB);
  //     });

  //     console.log('group:', group.GroupName);
  //     console.log('sortedFields:', sortedFields);

  //     return (
  //       <Accordion id={group.FieldGroupId.toString()} key={group.FieldGroupId} sx={{ marginBottom: 3 }}>
  //         <AccordionSummary
  //           sx={{
  //             backgroundColor: '#eeeeee',
  //             '&.Mui-expanded': { backgroundColor: '#e7edfb' }
  //           }}
  //         >
  //           {group.GroupName}
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           <Grid key={group.FieldGroupId.toString()} container spacing={3} display={"flex"} flexWrap={"wrap"}>
  //             {sortedFields && buildCustomFieldInputs2(sortedFields, allFields, group.FieldGroupId)}
  //           </Grid>
  //         </AccordionDetails>
  //       </Accordion>
  //     );
  //   });
  // };

  const deleteLater = 0

  // const createGroupAccordions2 = () => { //? this one works but is redundant, by grouping them, ungrouping to alphabetize, then regrouping them
  //   const groupedFields: Record<number, { groupName: string, fields: any[] }> = {};

  //   // Group fields by their group ID
  //   customFields?.data?.forEach((field: any) => {
  //     const fieldGroupId: number = field.ValueMeta?.C?.Meta?.Settings?.FieldGroupId;

  //     if (!groupedFields[fieldGroupId]) {
  //       const groupName = customFieldGroups?.data.find((group: any) => group.FieldGroupId === fieldGroupId)?.GroupName || 'Unknown Group';
  //       groupedFields[fieldGroupId] = {
  //         groupName: groupName,
  //         fields: []
  //       };
  //     }

  //     groupedFields[fieldGroupId].fields.push(field);
  //   });

  //   // Flatten all fields, sort alphabetically globally, and add back to groups
  //   const sortedAllFields = Object.values(groupedFields).reduce((acc: any[], group) => acc.concat(group.fields), []).sort((a: any, b: any) => {
  //     const nameA = a.ValueMeta?.C?.Meta?.Name?.toLowerCase() || '';
  //     const nameB = b.ValueMeta?.C?.Meta?.Name?.toLowerCase() || '';
  //     return nameA.localeCompare(nameB);
  //   });

  //   // Place the sorted fields back into their respective groups
  //   Object.keys(groupedFields).forEach(groupId => {
  //     groupedFields[Number(groupId)].fields = sortedAllFields.filter(
  //       field => field.ValueMeta?.C?.Meta?.Settings?.FieldGroupId === Number(groupId)
  //     );
  //   });

  //   const allFields = sortedAllFields;

  //   // Render each group with fields sorted globally
  //   return customFieldGroups?.data.map((group: any) => {
  //     const sortedFields = groupedFields[group.FieldGroupId]?.fields || [];

  //     console.log('group:', group.GroupName);
  //     console.log('sortedFields:', sortedFields);

  //     return (
  //       <Accordion id={group.FieldGroupId.toString()} key={group.FieldGroupId} sx={{ marginBottom: 3 }}>
  //         <AccordionSummary
  //           sx={{
  //             backgroundColor: '#eeeeee',
  //             '&.Mui-expanded': { backgroundColor: '#e7edfb' }
  //           }}
  //         >
  //           {group.GroupName}
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           <Grid key={group.FieldGroupId.toString()} container spacing={3} display={"flex"} flexWrap={"wrap"}>
  //             {sortedFields && buildCustomFieldInputs2(sortedFields, allFields, group.FieldGroupId)}
  //           </Grid>
  //         </AccordionDetails>
  //       </Accordion>
  //     );
  //   });
  // };

  // const createGroupAccordions2 = () => {
  //   const groupMap: Record<number, any> = {};

  //   // Create a map of field groups
  //   customFieldGroups?.data?.forEach((group: any) => {
  //     groupMap[group.FieldGroupId] = group;
  //   });

  //   const groupedFields: Record<number, { groupName: string, fields: any[] }> = {};

  //   // Loop through each custom field and assign it to its group
  //   customFields?.data?.forEach((field: any) => {
  //     const fieldGroupId: number = field.ValueMeta?.C?.Meta?.Settings?.FieldGroupId;

  //     // Ensure the group exists in the map, if not create an empty array for it
  //     if (!groupedFields[fieldGroupId]) {
  //       groupedFields[fieldGroupId] = {
  //         groupName: groupMap[fieldGroupId]?.GroupName || 'Unknown Group',
  //         fields: []
  //       };
  //     }

  //     // Add the field to the corresponding group
  //     groupedFields[fieldGroupId].fields.push(field);
  //   });

  //   // Sort the customFieldGroups with "General" first and others alphabetically
  //   const sortedGroups = [...customFieldGroups?.data].sort((a, b) => {
  //     if (a.GroupName === 'General') return -1;  // "General" comes first
  //     if (b.GroupName === 'General') return 1;
  //     return a.GroupName.localeCompare(b.GroupName);  // Sort alphabetically
  //   });

  //   // Render the sorted groups
  //   return sortedGroups.map((group: any) => {
  //     console.log('group:', group)
  //     // Sort the fields alphabetically by name before rendering
  //     const sortedFields = (groupedFields[group.FieldGroupId]?.fields || []).sort((a: any, b: any) => {
  //       const nameA = a.ValueMeta?.C?.Meta?.Name?.toLowerCase() || '';
  //       const nameB = b.ValueMeta?.C?.Meta?.Name?.toLowerCase() || '';
  //       return nameA.localeCompare(nameB);
  //     });

  //     console.log('sortedFields:', sortedFields)

  //     return (
  //       <Accordion id="test" key={group.FieldGroupId}>
  //         <AccordionSummary>{group.GroupName}: {group.FieldGroupId}</AccordionSummary>
  //         <AccordionDetails>
  //           {/* Render fields within each group, sorted alphabetically */}
  //           {sortedFields.map((field, index) => {
  //             console.log('field:', field)
  //             return (
  //               <TextField id={`test-${field.ValueMeta?.C?.Meta?.Cid}`} key={index} label={field.ValueMeta?.C?.Meta?.Name || 'Unknown Field'} />
  //             )
  //           })}
  //         </AccordionDetails>
  //       </Accordion>
  //     );
  //   })

  //   // return (
  //   //   <Accordion key={group.FieldGroupId}>
  //   //     <AccordionSummary>{group.GroupName}</AccordionSummary>
  //   //     <AccordionDetails>
  //   //       {/* Each group can have its own child accordions */}
  //   //       <Accordion key={child-${group.FieldGroupId}}>
  //   //         <AccordionSummary>Child Accordion {group.FieldGroupId}</AccordionSummary>
  //   //         <AccordionDetails>
  //   //           <TextField id={test1-${group.FieldGroupId}} label="Test 1" />
  //   //           <TextField id={test2-${group.FieldGroupId}} label="Test 2" />
  //   //         </AccordionDetails>
  //   //       </Accordion>
  //   //     </AccordionDetails>
  //   //   </Accordion>
  //   // )
  //   // return (
  //   //   <Accordion
  //   //     key={group.FieldGroupId}
  //   //   // name = { group.GroupName +  (${group.FieldsInGroup}) } 
  //   //   >
  //   //     <div>
  //   //       <Grid item xs={12}>
  //   //         {/* <FormProvider {...methods}>
  //   //           <form onSubmit={methods.handleSubmit(onSubmit)}> */}
  //   //         <Grid key={group.FieldGroupId} container spacing={3} display={"flex"} flexWrap={"wrap"}>
  //   //           {
  //   //             finalArray && finalArray.map((parent: any) => {
  //   //               if (parent.ValueMeta.C.Meta.Settings.FieldGroupId === group.FieldGroupId) {
  //   //                 return (
  //   //                   <>
  //   //                     <Grid item xs={12} sm={6} md={4}>
  //   //                       <Box marginY={1} gap={1.5} sx={{ width: "100%" }}>
  //   //                         {/* <input {...register("firstName", { required: true })} /> */}
  //   //                         {dynamicField(parent, methods, changer)}
  //   //                       </Box>
  //   //                     </Grid>
  //   //                     {parent.childFields.map((child: any) => {
  //   //                       // if (child.childFields.length > 0) {
  //   //                       //     {
  //   //                       //         child.childFields.map((childOfChild: any) => {
  //   //                       //             return (
  //   //                       //                 <>
  //   //                       //                     {dynamicChildField(childOfChild, methods, changer, checkType(parent), parent.ValueMeta.C.Meta.Cid)
  //   //                       //                     }
  //   //                       //                 </>

  //   //                       //             )
  //   //                       //         })
  //   //                       //     }
  //   //                       // }
  //   //                       return (
  //   //                         <>
  //   //                           {dynamicChildField(child, methods, changer, checkType(parent), parent.ValueMeta.C.Meta.Cid)
  //   //                           }
  //   //                         </>

  //   //                       )
  //   //                     })}
  //   //                   </>
  //   //                 )
  //   //               }
  //   //             })
  //   //           }
  //   //         </Grid>
  //   //         {/* </form>
  //   //         </FormProvider> */}
  //   //       </Grid >
  //   //     </div >
  //   //   </Accordion >
  //   // )

  // }

  const requiredColor = (trueCount: number, totalCount: number) => {
    if (trueCount === 0 && totalCount === 0) {
      return 'gray';
    } else if (trueCount === totalCount) {
      return 'green';
    } else {
      return 'red';
    }
  };

  const createGroupAccordions2 = () => {
    const allFieldValues = watch(); // This will allow us to check current form values dynamically

    // Sort all fields globally by Name
    const sortedAllFields = customFields?.data?.sort((a: any, b: any) => {
      const nameA = a.ValueMeta?.C?.Meta?.Name?.toLowerCase() || '';
      const nameB = b.ValueMeta?.C?.Meta?.Name?.toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });

    // Group the already sorted fields by their group ID
    const groupedFields: Record<number, { groupName: string, fields: any[] }> = {};

    sortedAllFields?.forEach((field: any) => {
      const fieldGroupId: number = field.ValueMeta?.C?.Meta?.Settings?.FieldGroupId;

      if (!groupedFields[fieldGroupId]) {
        const groupName = customFieldGroups?.data.find((group: any) => group.FieldGroupId === fieldGroupId)?.GroupName || 'Unknown Group';
        groupedFields[fieldGroupId] = {
          groupName: groupName,
          fields: []
        };
      }

      groupedFields[fieldGroupId].fields.push(field);
    });

    // Render each group with fields already sorted
    return customFieldGroups?.data.map((group: any) => {
      const sortedFields = groupedFields[group.FieldGroupId]?.fields || [];
      const groupId = group.FieldGroupId.toString();

      // Filter for visible and required fields to calculate the counts
      const visibleRequiredFields = sortedFields.filter(
        (field: any) => field.ValueMeta?.C?.Meta?.Settings?.IsRequired && checkAncestorsVisibility(field, sortedAllFields)
      );

      // Calculate totalCount based on visible required fields
      const totalCount = visibleRequiredFields.length;

      // Calculate trueCount based on fields with values
      const trueCount = visibleRequiredFields.reduce((count, field) => {
        const cid = field.ValueMeta.C.Meta.Cid.toString();
        const fieldValue = allFieldValues[cid];
        return fieldValue ? count + 1 : count; // Increment count if the field has a value
      }, 0);

      return (
        <Accordion id={group.FieldGroupId.toString()} key={group.FieldGroupId} sx={{ marginBottom: 4, '&::before': { display: 'none' } }}>
          <AccordionSummary
            sx={{
              '& .MuiAccordionSummary-content': {
                display: "flex",
                justifyContent: "space-between",
              },
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Grid>
              {group.GroupName}
            </Grid>
            <Grid sx={{ color: requiredColor(trueCount, totalCount), marginRight: 2 }}>
              {`Required ${trueCount}/${totalCount}`}
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 4 }}>
            <Grid key={group.FieldGroupId.toString()} container spacing={3} display={"flex"} flexWrap={"wrap"}>
              {sortedFields && buildCustomFieldInputs2(sortedFields, sortedAllFields, group.FieldGroupId)}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
  };


  const deepEqual = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  // const setTheRequiredFieldCount = (field: any, newValue?: any) => {
  //   if (field.ValueMeta?.C?.Meta?.Settings.IsRequired) {
  //     const fieldGroupId = field.ValueMeta?.C?.Meta?.Settings?.FieldGroupId
  //     const cfValue = newValue ? newValue : checkType(field)
  //     // console.log('cfValue:', cfValue)
  //     setRequiredFields((prevState: any) => {
  //       // console.log('prevState:', prevState)
  //       // Initialize group if it doesn't exist
  //       const groupData = prevState[fieldGroupId] || {};

  //       const hasValue = CFTypeIdDetail(field.ValueMeta.C.TypeId).ClassBinding === "cfTDropdown"
  //         ? (cfValue.label === "Select..."
  //           ? false
  //           : true)
  //         : !!cfValue

  //       // console.log('hasValue:', hasValue)

  //       return {
  //         ...prevState,
  //         [fieldGroupId]: {
  //           ...groupData,
  //           [field.ValueMeta.C.Meta.Cid]: hasValue,
  //         },
  //       };
  //     });
  //     return cfValue
  //   }
  // }

  useEffect(() => {
    if (customFields.data && ticketListState?.selectedTicket?.id && !deepEqual(customFields.data, customFieldValuesData)) {
      //? This useEffect will run everytime that the customFields API endpoint gets called. This will cause the inputs values to be reset everytime.
      //? By adding the deepEqual check, this will determine if the incoming data is actually different from the previous state and allow the data to be reset.
      const { data } = customFields

      if (data) {
        const customFieldValues = {};
        data.forEach((field: any, index: number) => {
          // console.log('data field:', field)
          const cid = field.ValueMeta.C.Meta.Cid.toString();
          const cfValue = checkType(field)
          const classBinding = CFTypeIdDetail(field.ValueMeta.C.TypeId).ClassBinding

          // field.ValueMeta?.C?.Meta?.Settings.IsRequired && setTheRequiredFieldCount(field)

          if (classBinding === "cfTDropdown") {
            const id = field.ValueMeta.C.Type.OptLabels.findIndex((opt: any) => opt === cfValue);
            return (customFieldValues as any)[cid] = {
              id: id,
              label: cfValue,
            }
          } else if (classBinding === "cfTDate") {
            // console.log('cfTDate:', cfValue)
            return (cfValue && cfValue !== "0001-01-01T00:00") ? (customFieldValues as any)[cid] = dayjs(cfValue) : (customFieldValues as any)[cid] = null
            // return (customFieldValues as any)[cid] = dayjs(cfValue)
          } else {
            // console.log('cfValue:', cid, ': ', cfValue);
            (customFieldValues as any)[cid] = cfValue;  // Collect values dynamically
          }
        });

        // console.log('useEffectcustomFieldValues:', customFieldValues)

        // methods.reset({
        //   ...methods.getValues(),  // Preserve existing form values
        //   ...customFieldValues,    // Add custom fields dynamically
        // });
        // setTimeout(() => {
        // console.log('setTimeout done')
        // console.log('customFieldValues before reset:', customFieldValues);
        methods.reset({
          ...methods.getValues(),
          ...customFieldValues,
        });
        // }, 7000);
        setCustomFieldValuesData(customFields.data);
      }

      // assignee: data?.Assignee
      //   ? {
      //     id: data.Assignee.AgentId,
      //     label: data.Assignee.Name,
      //   }
      //   : null,
      // clientName: data?.Ticket?.ClientName || '',
      // clientEmail: data?.Ticket?.ClientEmail || '',
      // clientPhone: data?.Ticket?.ClientPhone || '',
      // clientAddress: data?.Ticket?.ClientAddress || '',
      // });
    }
  }, [customFields.data, ticketListState?.selectedTicket?.id]);

  useEffect(() => {
    if (customFields.data) {
      const initialRequiredFields: { [key: string]: any } = {};

      customFields.data.forEach((field: any) => {
        if (field.ValueMeta?.C?.Meta?.Settings.IsRequired) {
          const fieldGroupId = field.ValueMeta.C.Meta.Settings.FieldGroupId;
          const cid = field.ValueMeta.C.Meta.Cid.toString();
          const inputElement = document.querySelector(`[name="${cid}"]`) as HTMLInputElement;

          initialRequiredFields[fieldGroupId] = {
            ...(initialRequiredFields[fieldGroupId] || {}),
            [cid]: !!inputElement?.value,
          };

          inputElement?.addEventListener("change", (event) => {
            const hasValue = !!((event.target as HTMLInputElement)?.value);
            console.log('hasValue:', hasValue);
            setRequiredFields((prevState: any) => ({
              ...prevState,
              [fieldGroupId]: {
                ...prevState[fieldGroupId],
                [cid]: hasValue,
              },
            }));
          });
        }
      });

      setRequiredFields(initialRequiredFields);
    }
  }, [customFields.data]);

  return (
    <>
      {/* <div> */}
      {customFieldGroups?.data && createGroupAccordions2()}
      {/* <Button onClick={() => { console.log('requiredFields:', requiredFields) }}>click me</Button> */}
      {/* </div> */}
    </>
  )
}

export default CustomFields